import CoinIcon from "@/assets/icons/coin-icon.png";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import {
	cn,
	formatDate,
	hasAnalyticCredit,
	openInNewTab,
} from "@/common/helper";
import Tooltip from "@/components/ui/tooltip";
import type { ListAnalytics, ListItem } from "@/services/master-services";
import { useBalanceStore } from "@/store/credit-stores";
import { useFeaturedCreatorStore } from "@/store/featured-creator-store";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	InfoIcon,
	LoaderCircleIcon,
	Percent,
	SearchIcon,
	TriangleAlert,
	UserSearch,
} from "lucide-react";
import { useEffect, useState } from "react";

function groupAndSortByDay(
	data: Array<ListItem>
): Record<string, Array<ListItem>> {
	const groupedData: Record<string, Array<ListItem>> = {};

	data.forEach((user) => {
		const date = new Date(user.updatedAt);
		const day = date.toISOString().split("T")[0]; // Extract the day in 'YYYY-MM-DD' format

		if (day) {
			// Ensure day is defined
			if (!(day in groupedData)) {
				groupedData[day] = [];
			}
			(groupedData[day] as Array<ListItem>).push(user);
		}
	});

	// Sort each day's entries by hours descending
	Object.keys(groupedData).forEach((day) => {
		(groupedData[day] as Array<ListItem>).sort((a, b) => {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		});
	});

	return groupedData;
}
interface IRecentAnalysis {
	listData: ListAnalytics;
	setRecentAnalyticsPage: (page: number) => void;
	currentPageParent: number;
	setFilterParent: (filter: string) => void;
	isLoading: boolean;
}

const RecentAnalysis: React.FC<IRecentAnalysis> = ({
	listData,
	setRecentAnalyticsPage,
	currentPageParent,
	setFilterParent,
	isLoading,
}) => {
	const [currentPage, setCurrentPage] = useState(currentPageParent);
	const [filter, setFilter] = useState("");
	useEffect(() => {
		setFilterParent(filter);
		setCurrentPage(1);
	}, [setFilterParent, filter, setRecentAnalyticsPage]);
	const totalPages = listData.maxPage;
	const handlePreviousPage = (): void => {
		setCurrentPage((previousPage) => Math.max(previousPage - 1, 1));
		setRecentAnalyticsPage(Math.max(currentPageParent - 1, 1));
	};
	const handleNextPage = (): void => {
		setCurrentPage((previousPage) => Math.min(previousPage + 1, totalPages));
		setRecentAnalyticsPage(Math.min(currentPageParent + 1, totalPages));
	};
	const balance = useBalanceStore((state) => state.balance);
	const listFreeCreatorStore = useFeaturedCreatorStore();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const query = event.target.value;
		setFilter(query);
	};
	const renderButtonAnalyze = (user: ListItem): JSX.Element => {
		if (user.progress === 300) {
			if (
				listFreeCreatorStore.freeCreatorList.some(
					(x) =>
						x.username == user.username &&
						x.platform.toLowerCase() == user.platform.toLowerCase()
				) ||
				hasAnalyticCredit(balance)
			) {
				return (
					<button
						className="flex justify-between gap-2 items-center text-orange-700 font-medium py-1 px-2 pl-3 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 rounded-2xl"
						onClick={() => {
							openInNewTab(`/discover/${user.platform}/${user.uuid}`);
						}}
					>
						<div className="flex items-center justify-center w-4 h-4 bg-orange-500 rounded-full">
							<img src={CoinIcon} alt="" />
						</div>
						<span className="">View</span>
					</button>
				);
			}
			return (
				<button
					className="flex flex-row flex-nowrap justify-center
					items-center text-green-500 font-semibold gap-2 py-1 px-2 w-full border border-green-500 focus:outline-none hover:bg-green-200 hover:rounded-3xl rounded-2xl"
					onClick={() => {
						openInNewTab(`/discover/${user.platform}/${user.uuid}`);
					}}
				>
					<div className="flex items-center justify-center w-5 h-5 bg-green-500 rounded-full">
						<Percent size={12} color="#fff"></Percent>
					</div>
					<span className="whitespace-nowrap">View</span>
				</button>
			);
		} else if (user.progress === 0) {
			return (
				<button
					disabled
					className="flex flex-nowrap justify-center items-center cursor-progress text-grey-200 font-semibold gap-2 w-full"
				>
					Preparing{" "}
					{user.attemptsMade >= 3 ? (
						<TriangleAlert color="#FAA61A" size={16}></TriangleAlert>
					) : (
						<InfoIcon size={16} />
					)}
				</button>
			);
		}

		return (
			<button
				disabled
				className="flex justify-center items-center text-[red] font-semibold gap-2 py-1 px-4 w-full border border-[red] focus:outline-none rounded-2xl"
			>
				<span className="">Failed</span>
			</button>
		);
	};

	const renderListData = (): JSX.Element => {
		const groupedData = groupAndSortByDay(listData.data);
		return (
			<>
				{Object.entries(groupedData).map(([day, users], index) => (
					<div key={index}>
						<span className="text-sm text-grey-400 font-medium">
							{formatDate(day)}
						</span>
						<ul className="flex flex-col gap-2 mt-2">
							{users.map((user) => (
								<li
									className="flex justify-between items-center gap-2 my-2 sm:my-0"
									key={user.uuid}
								>
									<div className="flex items-center gap-2">
										<div>
											<img
												src={
													user.platform == "instagram" ? InstaIcon : TiktokIcon
												}
												alt="insta-icon"
											/>
										</div>
										<div className="flex gap-0 sm:gap-2 flex-col sm:flex-row">
											{user.name && (
												<span className=" text-grey-800 font-medium">
													{user.name}
												</span>
											)}
											<span className=" text-grey-400 font-medium">
												@{user.username}
											</span>
										</div>
									</div>
									<div>{renderButtonAnalyze(user)}</div>
								</li>
							))}
						</ul>
					</div>
				))}
			</>
		);
	};
	return (
		<div className="flex flex-col py-5 px-5 sm:px-7 gap-4 bg-grey-100 text-grey-800 rounded-xl shadow">
			<div className="flex items-center justify-between gap-5">
				<Tooltip tooltipContent="You can view historical analysis from the last 14 days for free.">
					<span className="font-semibold flex items-center">
						Recent Analysis
						<InfoIcon className="ml-2 text-grey-300" size={16} />
					</span>
				</Tooltip>
				<div
					className="w-full
					md:max-w-80 flex items-center ring-1 rounded-xl border transition-colors
					ring-gray-300
					focus-visible:outline-none
					focus-visible:ring-1 focus-within:ring-orange-500 bg-white px-2"
				>
					<input
						type="text"
						className={cn(
							"ml-2 border-none placeholder:text-muted-foreground flex h-10 w-full bg-transparent p-0 text-grey-800 outline-none"
						)}
						placeholder="Search name or username"
						onChange={handleChange}
						value={filter}
					/>
					<SearchIcon
						className="top-4 w-10 self-center border-none"
						size={20}
						color="#BFBFBF"
					/>
				</div>
			</div>

			<div className="flex flex-col gap-2 xl:gap-4 min-h-[170px]">
				{isLoading ? (
					<div className="h-full w-full flex justify-center items-center">
						<LoaderCircleIcon
							className="animate-spin text-gray-500"
							size={50}
						/>
					</div>
				) : listData && listData.data.length > 0 ? (
					renderListData()
				) : (
					<div className="flex flex-col justify-center items-center xl:py-16">
						<span className="hidden xl:block">
							<UserSearch size={48} color="#808080"></UserSearch>
						</span>
						<span className="xl:hidden">
							<UserSearch size={20} color="#808080"></UserSearch>
						</span>
						<span className="text-grey-400 xl:my-4">
							No Recent Analysis Data
						</span>
					</div>
				)}
			</div>

			<div className="flex justify-end items-center mt-4 gap-1">
				{currentPage > 1 && (
					<button
						className="w-8 h-8 flex justify-center items-center hover:bg-orange-200 rounded-full group "
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon className="text-gray-500 group-hover:text-orange-500" />
					</button>
				)}
				<span className="w-8 h-8 flex justify-center items-center rounded-full text-white bg-orange-500">
					{currentPage}
				</span>
				{currentPage < totalPages && (
					<button
						className="w-8 h-8 flex justify-center items-center hover:bg-orange-200 rounded-full group"
						onClick={handleNextPage}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon className="text-gray-500 group-hover:text-orange-500" />
					</button>
				)}
			</div>
		</div>
	);
};

export default RecentAnalysis;
