import Coins from "@/assets/icons/Coins.svg";
import AnalyticsLogo from "@/assets/images/analytics-main-logo.png";
import { cn, formatNumber, remainingCoins } from "@/common/helper";
import PaymentComponent from "@/components/subscription/midtrans";
import Button from "@/components/ui/button";
import { useGeneratePayment } from "@/hooks/payment-hooks";
import { getBalance } from "@/services/account-services";
import tokenServices from "@/services/token-services";
import { useBalanceStore } from "@/store/credit-stores";
import type { User } from "@/types/master";
import { Popover, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate, useRouterState } from "@tanstack/react-router";
import {
	ChevronDown,
	LoaderCircleIcon,
	LogOutIcon,
	PlusIcon,
	SettingsIcon,
} from "lucide-react";
import { Fragment, useEffect } from "react";

type PopoverType = {
	label: string;
	href: string;
	icon: React.ReactNode;
	clickEvent?: () => void;
};

type IHeader = {
	refreshKey: number | null;
};
const Header: React.FC<IHeader> = ({ refreshKey = null }) => {
	const router = useRouterState();
	const navigate = useNavigate();
	const generatePayment = useGeneratePayment();

	const user: User = tokenServices.getUser() || {
		email: null,
		name: null,
		isEmailVerified: false,
	};
	useEffect(() => {
		if (user?.email) {
			const scriptTag = document.createElement("script");
			scriptTag.src = import.meta.env["VITE_SB_MIDTRANS_URL"] as string;
			scriptTag.dataset["clientKey"] = import.meta.env[
				"VITE_SB_MIDTRANS_KEY"
			] as string;

			document.body.append(scriptTag);

			return () => {
				scriptTag.remove();
			};
		}
		return;
	}, [user?.email]);

	const handleNavigate = (path: string): void => {
		void navigate({ to: path }).catch((error) => {
			console.error("Navigation error:", error);
		});
	};
	const setCreditData = useBalanceStore((state) => state.setBalance);
	const resetBalance = useBalanceStore((state) => state.resetBalance);
	const balance = useBalanceStore((state) => state.balance);

	const popoverItem: Array<PopoverType> = [
		{
			label: "Settings",
			href: "/settings/my-account",
			icon: <SettingsIcon size={16} />,
		},
		{
			label: "Log out",
			href: "/login",
			icon: <LogOutIcon size={16} />,
			clickEvent: (): void => {
				tokenServices.removeUser();
				resetBalance();
			},
		},
	];

	const { isPending: isBalancePending, refetch } = useQuery({
		queryKey: [`get-balance`],
		queryFn: async () => {
			const resp = await getBalance();
			setCreditData(resp);
			return resp;
		},
		retry: 1,

		refetchOnWindowFocus: true,
		enabled: !!user.email,
	});

	useEffect(() => {
		if (user.email && refreshKey)
			refetch().catch((error) => {
				console.error(error);
			});
	}, [refreshKey, user.email, refetch]);

	return (
		<header className="flex w-full h-20 max-h-40 bg-white border border-b-grey-100 shadow-sm py-4 justify-center">
			<div className="flex w-full max-w-screen-2xl justify-between mx-2">
				{/* Company Logo */}
				<div className="flex items-center gap-2">
					<div
						className="w-20 md:w-40 h-fit cursor-pointer"
						onClick={() => {
							user.email && user.isEmailVerified
								? handleNavigate("/discover")
								: handleNavigate("/");
						}}
					>
						<img src={AnalyticsLogo} alt="analytics-logo" />
					</div>
					{/* {user.email && (
						<div className="flex flex-row mx-2 md:mx-8 rounded-full border border-gray-300 px-1 py-1 min-w-fit items-center justify-end hidden lg:block">
							<span className="flex text-sm text-gray-500 items-center mx-2">
								Standard Subscription
							</span>
							<span className="flex text-white bg-orange-400 py-0.5 pb-[6px] px-2 rounded-full items-center cursor-pointer"
							onClick={() => {}}
							>
								Upgrade
							</span>
						</div>
					)} */}
				</div>

				{user.email === null || !user.isEmailVerified ? (
					<div className="flex justify-end w-full items-center">
						<div className="flex gap-4 w-fit">
							<Button
								size="sm"
								className="hidden md:block"
								variant="secondary"
								onClick={() => {
									handleNavigate("/register");
								}}
							>
								Register
							</Button>
							<Button
								size="sm"
								variant="primary"
								onClick={() => {
									handleNavigate("/login");
								}}
							>
								Login
							</Button>
						</div>
					</div>
				) : (
					<div className="flex justify-end w-full items-center gap-2">
						<div className="flex flex-row flex-nowrap items-center p-2 pl-3 gap-4 w-fit">
							<div className="flex flex-col items-end">
								<span className="text-grey-400 font-light text-sm hidden md:block">
									Analytics Coins
								</span>
								<div className="flex items-center">
									<img src={Coins} alt="icons" className="h-6 w-6 mr-1" />
									<span className="font-semibold text-lg text-orange-700">
										{isBalancePending && (
											<LoaderCircleIcon className="animate-spin" />
										)}
										{formatNumber(remainingCoins(balance))}
									</span>
								</div>
							</div>

							<PaymentComponent>
								{(isPending: boolean): JSX.Element => (
									<Button
										size="xs"
										variant="primary"
										className="rounded-full w-fit p-1"
										disabled={isPending}
										startIcon={
											generatePayment.isPending && (
												<LoaderCircleIcon className="animate-spin" />
											)
										}
									>
										<PlusIcon />
									</Button>
								)}
							</PaymentComponent>
						</div>

						{/* <div>
							<button className="flex justify-center items-center w-6 h-6">
								<Bell color="#BFBFBF" fill="#BFBFBF" size={24} />
							</button>
						</div> */}

						<Popover className="flex justify-end relative ml-4">
							<Popover.Button className="flex justify-end items-center gap-4  text-grey-500 font-medium focus:outline-none">
								<span className="capitalize text-end">
									{user ? user.name : "John Doe"}
								</span>
								<ChevronDown color="#5B5C5E" />
							</Popover.Button>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-0"
								enterTo="opacity-100 translate-y-1"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-1"
								leaveTo="opacity-0 translate-y-0"
							>
								<Popover.Panel className="absolute z-10 mt-8 w-28 bg-white p-2 shadow rounded">
									<ul className="flex flex-col gap-2 items-center">
										{popoverItem.map((item: PopoverType, index: number) => (
											<Link
												to={item.href}
												activeProps={{
													style: {
														fontWeight: "bold",
														backgroundColor: "#F8F8F8",
													},
												}}
												key={index}
												className="w-full justify-center flex"
												onClick={item.clickEvent ?? undefined}
											>
												<li
													className={cn(
														"flex items-center gap-2 py-2 hover:bg-grey-100 rounded w-full px-2 justify-around",
														router.location.pathname.includes("/settings") &&
															item.href.includes("/settings") &&
															"font-semibold bg-grey-100",
														router.location.pathname.includes("/my-plan") &&
															item.href.includes("/my-plan") &&
															"font-semibold bg-grey-100"
													)}
												>
													{item.icon}
													<span className="text-sm">{item.label}</span>
												</li>
											</Link>
										))}
									</ul>
								</Popover.Panel>
							</Transition>
						</Popover>
					</div>
				)}
			</div>
		</header>
	);
};

export default Header;
