/* eslint-disable unicorn/filename-case */

import DiscoverDetail from "@/pages/discover/detail";
import { getAnalyticsDetail } from "@/services/master-services";
import tokenServices from "@/services/token-services";
import type { SocialMediaPlatform } from "@/types/master";
import { createFileRoute, defer, redirect } from "@tanstack/react-router";
import type { UUID } from "node:crypto";

export const Route = createFileRoute("/discover_/$platform/$userSearchUUID")({
	loader: ({
		params,
	}: {
		params: { userSearchUUID: UUID; platform: string };
	}) => {
		try {
			const dataPromise = getAnalyticsDetail({
				platform: params.platform as SocialMediaPlatform,
				userSearchUUID: params.userSearchUUID,
			});
			return { data: defer(dataPromise) };
		} catch (error) {
			console.error(error);
			return { data: defer(Promise.reject(null)) };
			// throw redirect({ to: "/discover" });
		}
	},
	component: DiscoverDetail,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
