/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as VerifyEmailImport } from "./routes/verify-email";
import { Route as TermsAndConditionsImport } from "./routes/terms-and-conditions";
import { Route as ResetPasswordImport } from "./routes/reset-password";
import { Route as RegisterImport } from "./routes/register";
import { Route as PrivacyPolicyImport } from "./routes/privacy-policy";
import { Route as PricingImport } from "./routes/pricing";
import { Route as LoginImport } from "./routes/login";
import { Route as ForgotPasswordImport } from "./routes/forgot-password";
import { Route as EmailVerificationImport } from "./routes/email-verification";
import { Route as DiscoverImport } from "./routes/discover";
import { Route as DashboardImport } from "./routes/dashboard";
import { Route as IndexImport } from "./routes/index";
import { Route as SettingsPaymentImport } from "./routes/settings.payment";
import { Route as SettingsMyPlanImport } from "./routes/settings.my-plan";
import { Route as SettingsMyAccountImport } from "./routes/settings.my-account";
import { Route as PublicSearchImport } from "./routes/public.search";
import { Route as SettingsPaymentEditPaymentImport } from "./routes/settings.payment_.edit-payment";
import { Route as SettingsMyAccountEditProfileImport } from "./routes/settings.my-account_.edit-profile";
import { Route as DiscoverPlatformUserSearchUUIDImport } from "./routes/discover_.$platform.$userSearchUUID";

// Create/Update Routes

const VerifyEmailRoute = VerifyEmailImport.update({
	id: "/verify-email",
	path: "/verify-email",
	getParentRoute: () => rootRoute,
} as any);

const TermsAndConditionsRoute = TermsAndConditionsImport.update({
	id: "/terms-and-conditions",
	path: "/terms-and-conditions",
	getParentRoute: () => rootRoute,
} as any);

const ResetPasswordRoute = ResetPasswordImport.update({
	id: "/reset-password",
	path: "/reset-password",
	getParentRoute: () => rootRoute,
} as any);

const RegisterRoute = RegisterImport.update({
	id: "/register",
	path: "/register",
	getParentRoute: () => rootRoute,
} as any);

const PrivacyPolicyRoute = PrivacyPolicyImport.update({
	id: "/privacy-policy",
	path: "/privacy-policy",
	getParentRoute: () => rootRoute,
} as any);

const PricingRoute = PricingImport.update({
	id: "/pricing",
	path: "/pricing",
	getParentRoute: () => rootRoute,
} as any);

const LoginRoute = LoginImport.update({
	id: "/login",
	path: "/login",
	getParentRoute: () => rootRoute,
} as any);

const ForgotPasswordRoute = ForgotPasswordImport.update({
	id: "/forgot-password",
	path: "/forgot-password",
	getParentRoute: () => rootRoute,
} as any);

const EmailVerificationRoute = EmailVerificationImport.update({
	id: "/email-verification",
	path: "/email-verification",
	getParentRoute: () => rootRoute,
} as any);

const DiscoverRoute = DiscoverImport.update({
	id: "/discover",
	path: "/discover",
	getParentRoute: () => rootRoute,
} as any);

const DashboardRoute = DashboardImport.update({
	id: "/dashboard",
	path: "/dashboard",
	getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
	id: "/",
	path: "/",
	getParentRoute: () => rootRoute,
} as any);

const SettingsPaymentRoute = SettingsPaymentImport.update({
	id: "/settings/payment",
	path: "/settings/payment",
	getParentRoute: () => rootRoute,
} as any);

const SettingsMyPlanRoute = SettingsMyPlanImport.update({
	id: "/settings/my-plan",
	path: "/settings/my-plan",
	getParentRoute: () => rootRoute,
} as any);

const SettingsMyAccountRoute = SettingsMyAccountImport.update({
	id: "/settings/my-account",
	path: "/settings/my-account",
	getParentRoute: () => rootRoute,
} as any);

const PublicSearchRoute = PublicSearchImport.update({
	id: "/public/search",
	path: "/public/search",
	getParentRoute: () => rootRoute,
} as any);

const SettingsPaymentEditPaymentRoute = SettingsPaymentEditPaymentImport.update(
	{
		id: "/settings/payment_/edit-payment",
		path: "/settings/payment/edit-payment",
		getParentRoute: () => rootRoute,
	} as any
);

const SettingsMyAccountEditProfileRoute =
	SettingsMyAccountEditProfileImport.update({
		id: "/settings/my-account_/edit-profile",
		path: "/settings/my-account/edit-profile",
		getParentRoute: () => rootRoute,
	} as any);

const DiscoverPlatformUserSearchUUIDRoute =
	DiscoverPlatformUserSearchUUIDImport.update({
		id: "/discover_/$platform/$userSearchUUID",
		path: "/discover/$platform/$userSearchUUID",
		getParentRoute: () => rootRoute,
	} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
	interface FileRoutesByPath {
		"/": {
			id: "/";
			path: "/";
			fullPath: "/";
			preLoaderRoute: typeof IndexImport;
			parentRoute: typeof rootRoute;
		};
		"/dashboard": {
			id: "/dashboard";
			path: "/dashboard";
			fullPath: "/dashboard";
			preLoaderRoute: typeof DashboardImport;
			parentRoute: typeof rootRoute;
		};
		"/discover": {
			id: "/discover";
			path: "/discover";
			fullPath: "/discover";
			preLoaderRoute: typeof DiscoverImport;
			parentRoute: typeof rootRoute;
		};
		"/email-verification": {
			id: "/email-verification";
			path: "/email-verification";
			fullPath: "/email-verification";
			preLoaderRoute: typeof EmailVerificationImport;
			parentRoute: typeof rootRoute;
		};
		"/forgot-password": {
			id: "/forgot-password";
			path: "/forgot-password";
			fullPath: "/forgot-password";
			preLoaderRoute: typeof ForgotPasswordImport;
			parentRoute: typeof rootRoute;
		};
		"/login": {
			id: "/login";
			path: "/login";
			fullPath: "/login";
			preLoaderRoute: typeof LoginImport;
			parentRoute: typeof rootRoute;
		};
		"/pricing": {
			id: "/pricing";
			path: "/pricing";
			fullPath: "/pricing";
			preLoaderRoute: typeof PricingImport;
			parentRoute: typeof rootRoute;
		};
		"/privacy-policy": {
			id: "/privacy-policy";
			path: "/privacy-policy";
			fullPath: "/privacy-policy";
			preLoaderRoute: typeof PrivacyPolicyImport;
			parentRoute: typeof rootRoute;
		};
		"/register": {
			id: "/register";
			path: "/register";
			fullPath: "/register";
			preLoaderRoute: typeof RegisterImport;
			parentRoute: typeof rootRoute;
		};
		"/reset-password": {
			id: "/reset-password";
			path: "/reset-password";
			fullPath: "/reset-password";
			preLoaderRoute: typeof ResetPasswordImport;
			parentRoute: typeof rootRoute;
		};
		"/terms-and-conditions": {
			id: "/terms-and-conditions";
			path: "/terms-and-conditions";
			fullPath: "/terms-and-conditions";
			preLoaderRoute: typeof TermsAndConditionsImport;
			parentRoute: typeof rootRoute;
		};
		"/verify-email": {
			id: "/verify-email";
			path: "/verify-email";
			fullPath: "/verify-email";
			preLoaderRoute: typeof VerifyEmailImport;
			parentRoute: typeof rootRoute;
		};
		"/public/search": {
			id: "/public/search";
			path: "/public/search";
			fullPath: "/public/search";
			preLoaderRoute: typeof PublicSearchImport;
			parentRoute: typeof rootRoute;
		};
		"/settings/my-account": {
			id: "/settings/my-account";
			path: "/settings/my-account";
			fullPath: "/settings/my-account";
			preLoaderRoute: typeof SettingsMyAccountImport;
			parentRoute: typeof rootRoute;
		};
		"/settings/my-plan": {
			id: "/settings/my-plan";
			path: "/settings/my-plan";
			fullPath: "/settings/my-plan";
			preLoaderRoute: typeof SettingsMyPlanImport;
			parentRoute: typeof rootRoute;
		};
		"/settings/payment": {
			id: "/settings/payment";
			path: "/settings/payment";
			fullPath: "/settings/payment";
			preLoaderRoute: typeof SettingsPaymentImport;
			parentRoute: typeof rootRoute;
		};
		"/discover_/$platform/$userSearchUUID": {
			id: "/discover_/$platform/$userSearchUUID";
			path: "/discover/$platform/$userSearchUUID";
			fullPath: "/discover/$platform/$userSearchUUID";
			preLoaderRoute: typeof DiscoverPlatformUserSearchUUIDImport;
			parentRoute: typeof rootRoute;
		};
		"/settings/my-account_/edit-profile": {
			id: "/settings/my-account_/edit-profile";
			path: "/settings/my-account/edit-profile";
			fullPath: "/settings/my-account/edit-profile";
			preLoaderRoute: typeof SettingsMyAccountEditProfileImport;
			parentRoute: typeof rootRoute;
		};
		"/settings/payment_/edit-payment": {
			id: "/settings/payment_/edit-payment";
			path: "/settings/payment/edit-payment";
			fullPath: "/settings/payment/edit-payment";
			preLoaderRoute: typeof SettingsPaymentEditPaymentImport;
			parentRoute: typeof rootRoute;
		};
	}
}

// Create and export the route tree

export interface FileRoutesByFullPath {
	"/": typeof IndexRoute;
	"/dashboard": typeof DashboardRoute;
	"/discover": typeof DiscoverRoute;
	"/email-verification": typeof EmailVerificationRoute;
	"/forgot-password": typeof ForgotPasswordRoute;
	"/login": typeof LoginRoute;
	"/pricing": typeof PricingRoute;
	"/privacy-policy": typeof PrivacyPolicyRoute;
	"/register": typeof RegisterRoute;
	"/reset-password": typeof ResetPasswordRoute;
	"/terms-and-conditions": typeof TermsAndConditionsRoute;
	"/verify-email": typeof VerifyEmailRoute;
	"/public/search": typeof PublicSearchRoute;
	"/settings/my-account": typeof SettingsMyAccountRoute;
	"/settings/my-plan": typeof SettingsMyPlanRoute;
	"/settings/payment": typeof SettingsPaymentRoute;
	"/discover/$platform/$userSearchUUID": typeof DiscoverPlatformUserSearchUUIDRoute;
	"/settings/my-account/edit-profile": typeof SettingsMyAccountEditProfileRoute;
	"/settings/payment/edit-payment": typeof SettingsPaymentEditPaymentRoute;
}

export interface FileRoutesByTo {
	"/": typeof IndexRoute;
	"/dashboard": typeof DashboardRoute;
	"/discover": typeof DiscoverRoute;
	"/email-verification": typeof EmailVerificationRoute;
	"/forgot-password": typeof ForgotPasswordRoute;
	"/login": typeof LoginRoute;
	"/pricing": typeof PricingRoute;
	"/privacy-policy": typeof PrivacyPolicyRoute;
	"/register": typeof RegisterRoute;
	"/reset-password": typeof ResetPasswordRoute;
	"/terms-and-conditions": typeof TermsAndConditionsRoute;
	"/verify-email": typeof VerifyEmailRoute;
	"/public/search": typeof PublicSearchRoute;
	"/settings/my-account": typeof SettingsMyAccountRoute;
	"/settings/my-plan": typeof SettingsMyPlanRoute;
	"/settings/payment": typeof SettingsPaymentRoute;
	"/discover/$platform/$userSearchUUID": typeof DiscoverPlatformUserSearchUUIDRoute;
	"/settings/my-account/edit-profile": typeof SettingsMyAccountEditProfileRoute;
	"/settings/payment/edit-payment": typeof SettingsPaymentEditPaymentRoute;
}

export interface FileRoutesById {
	__root__: typeof rootRoute;
	"/": typeof IndexRoute;
	"/dashboard": typeof DashboardRoute;
	"/discover": typeof DiscoverRoute;
	"/email-verification": typeof EmailVerificationRoute;
	"/forgot-password": typeof ForgotPasswordRoute;
	"/login": typeof LoginRoute;
	"/pricing": typeof PricingRoute;
	"/privacy-policy": typeof PrivacyPolicyRoute;
	"/register": typeof RegisterRoute;
	"/reset-password": typeof ResetPasswordRoute;
	"/terms-and-conditions": typeof TermsAndConditionsRoute;
	"/verify-email": typeof VerifyEmailRoute;
	"/public/search": typeof PublicSearchRoute;
	"/settings/my-account": typeof SettingsMyAccountRoute;
	"/settings/my-plan": typeof SettingsMyPlanRoute;
	"/settings/payment": typeof SettingsPaymentRoute;
	"/discover_/$platform/$userSearchUUID": typeof DiscoverPlatformUserSearchUUIDRoute;
	"/settings/my-account_/edit-profile": typeof SettingsMyAccountEditProfileRoute;
	"/settings/payment_/edit-payment": typeof SettingsPaymentEditPaymentRoute;
}

export interface FileRouteTypes {
	fileRoutesByFullPath: FileRoutesByFullPath;
	fullPaths:
		| "/"
		| "/dashboard"
		| "/discover"
		| "/email-verification"
		| "/forgot-password"
		| "/login"
		| "/pricing"
		| "/privacy-policy"
		| "/register"
		| "/reset-password"
		| "/terms-and-conditions"
		| "/verify-email"
		| "/public/search"
		| "/settings/my-account"
		| "/settings/my-plan"
		| "/settings/payment"
		| "/discover/$platform/$userSearchUUID"
		| "/settings/my-account/edit-profile"
		| "/settings/payment/edit-payment";
	fileRoutesByTo: FileRoutesByTo;
	to:
		| "/"
		| "/dashboard"
		| "/discover"
		| "/email-verification"
		| "/forgot-password"
		| "/login"
		| "/pricing"
		| "/privacy-policy"
		| "/register"
		| "/reset-password"
		| "/terms-and-conditions"
		| "/verify-email"
		| "/public/search"
		| "/settings/my-account"
		| "/settings/my-plan"
		| "/settings/payment"
		| "/discover/$platform/$userSearchUUID"
		| "/settings/my-account/edit-profile"
		| "/settings/payment/edit-payment";
	id:
		| "__root__"
		| "/"
		| "/dashboard"
		| "/discover"
		| "/email-verification"
		| "/forgot-password"
		| "/login"
		| "/pricing"
		| "/privacy-policy"
		| "/register"
		| "/reset-password"
		| "/terms-and-conditions"
		| "/verify-email"
		| "/public/search"
		| "/settings/my-account"
		| "/settings/my-plan"
		| "/settings/payment"
		| "/discover_/$platform/$userSearchUUID"
		| "/settings/my-account_/edit-profile"
		| "/settings/payment_/edit-payment";
	fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
	IndexRoute: typeof IndexRoute;
	DashboardRoute: typeof DashboardRoute;
	DiscoverRoute: typeof DiscoverRoute;
	EmailVerificationRoute: typeof EmailVerificationRoute;
	ForgotPasswordRoute: typeof ForgotPasswordRoute;
	LoginRoute: typeof LoginRoute;
	PricingRoute: typeof PricingRoute;
	PrivacyPolicyRoute: typeof PrivacyPolicyRoute;
	RegisterRoute: typeof RegisterRoute;
	ResetPasswordRoute: typeof ResetPasswordRoute;
	TermsAndConditionsRoute: typeof TermsAndConditionsRoute;
	VerifyEmailRoute: typeof VerifyEmailRoute;
	PublicSearchRoute: typeof PublicSearchRoute;
	SettingsMyAccountRoute: typeof SettingsMyAccountRoute;
	SettingsMyPlanRoute: typeof SettingsMyPlanRoute;
	SettingsPaymentRoute: typeof SettingsPaymentRoute;
	DiscoverPlatformUserSearchUUIDRoute: typeof DiscoverPlatformUserSearchUUIDRoute;
	SettingsMyAccountEditProfileRoute: typeof SettingsMyAccountEditProfileRoute;
	SettingsPaymentEditPaymentRoute: typeof SettingsPaymentEditPaymentRoute;
}

const rootRouteChildren: RootRouteChildren = {
	IndexRoute: IndexRoute,
	DashboardRoute: DashboardRoute,
	DiscoverRoute: DiscoverRoute,
	EmailVerificationRoute: EmailVerificationRoute,
	ForgotPasswordRoute: ForgotPasswordRoute,
	LoginRoute: LoginRoute,
	PricingRoute: PricingRoute,
	PrivacyPolicyRoute: PrivacyPolicyRoute,
	RegisterRoute: RegisterRoute,
	ResetPasswordRoute: ResetPasswordRoute,
	TermsAndConditionsRoute: TermsAndConditionsRoute,
	VerifyEmailRoute: VerifyEmailRoute,
	PublicSearchRoute: PublicSearchRoute,
	SettingsMyAccountRoute: SettingsMyAccountRoute,
	SettingsMyPlanRoute: SettingsMyPlanRoute,
	SettingsPaymentRoute: SettingsPaymentRoute,
	DiscoverPlatformUserSearchUUIDRoute: DiscoverPlatformUserSearchUUIDRoute,
	SettingsMyAccountEditProfileRoute: SettingsMyAccountEditProfileRoute,
	SettingsPaymentEditPaymentRoute: SettingsPaymentEditPaymentRoute,
};

export const routeTree = rootRoute
	._addFileChildren(rootRouteChildren)
	._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.ts",
      "children": [
        "/",
        "/dashboard",
        "/discover",
        "/email-verification",
        "/forgot-password",
        "/login",
        "/pricing",
        "/privacy-policy",
        "/register",
        "/reset-password",
        "/terms-and-conditions",
        "/verify-email",
        "/public/search",
        "/settings/my-account",
        "/settings/my-plan",
        "/settings/payment",
        "/discover_/$platform/$userSearchUUID",
        "/settings/my-account_/edit-profile",
        "/settings/payment_/edit-payment"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/dashboard": {
      "filePath": "dashboard.ts"
    },
    "/discover": {
      "filePath": "discover.ts"
    },
    "/email-verification": {
      "filePath": "email-verification.ts"
    },
    "/forgot-password": {
      "filePath": "forgot-password.ts"
    },
    "/login": {
      "filePath": "login.ts"
    },
    "/pricing": {
      "filePath": "pricing.ts"
    },
    "/privacy-policy": {
      "filePath": "privacy-policy.ts"
    },
    "/register": {
      "filePath": "register.ts"
    },
    "/reset-password": {
      "filePath": "reset-password.ts"
    },
    "/terms-and-conditions": {
      "filePath": "terms-and-conditions.ts"
    },
    "/verify-email": {
      "filePath": "verify-email.ts"
    },
    "/public/search": {
      "filePath": "public.search.ts"
    },
    "/settings/my-account": {
      "filePath": "settings.my-account.ts"
    },
    "/settings/my-plan": {
      "filePath": "settings.my-plan.ts"
    },
    "/settings/payment": {
      "filePath": "settings.payment.ts"
    },
    "/discover_/$platform/$userSearchUUID": {
      "filePath": "discover_.$platform.$userSearchUUID.ts"
    },
    "/settings/my-account_/edit-profile": {
      "filePath": "settings.my-account_.edit-profile.ts"
    },
    "/settings/payment_/edit-payment": {
      "filePath": "settings.payment_.edit-payment.ts"
    }
  }
}
ROUTE_MANIFEST_END */
