import { formatToIDR } from "@/common/helper";
import { Layout } from "@/components/layout";
import Button from "@/components/ui/button";
import tokenServices from "@/services/token-services";
import { CheckCircle2Icon, CircleX } from "lucide-react";
import { useEffect } from "react";

export interface IPayment {
	coins: number;
	code?: string;
}

const Pricing: React.FC = () => {
	// const generatePayment = useGeneratePayment();
	// const navigate = useNavigate();

	const user = tokenServices.getUser();
	// const planInfo = tokenServices.getPlanInfo() || { status: "" };

	// const isLoggedIn = user && user.email ? true : false;
	// const { data: subscriptionInfo, isPending: isSubscriptionInfoPending } =
	// 	useQuery({
	// 		queryKey: ["get-subscription-info"],
	// 		queryFn: async () => {
	// 			const resp = await getSubscriptionInfo();
	// 			if (resp.status !== "ACTIVE") return null;
	// 			return resp;
	// 		},
	// 		enabled: isLoggedIn,
	// 	});
	useEffect(() => {
		if (user?.email) {
			const scriptTag = document.createElement("script");
			scriptTag.src = import.meta.env["VITE_SB_MIDTRANS_URL"] as string;
			scriptTag.dataset["clientKey"] = import.meta.env[
				"VITE_SB_MIDTRANS_KEY"
			] as string;

			document.body.append(scriptTag);

			return () => {
				scriptTag.remove();
			};
		}
		return;
	}, [user?.email]);

	return (
		<Layout withSidebar={false}>
			<div className="flex flex-col items-center justify-center my-10 gap-20 w-full pb-10">
				<div className="flex flex-col items-center justify-center gap-4 mx-2">
					<h1 className="text-center text-[48px] font-semibold text-grey-500">
						Drive better decisions with{" "}
						<span className="text-orange-400">Power Subscription</span>
					</h1>
				</div>

				<div className="flex flex-col sm:flex-row items-center justify-center gap-[30px]">
					<div className="flex flex-col bg-white w-[40%] text-grey-800 pt-5 border border-grey-150 rounded-xl justify-between">
						<div className="flex flex-col w-full text-center ">
							<span className="text-2xl">Free</span>
							<span className="text-3xl font-medium my-2">
								{formatToIDR(0)} / month
							</span>
							<span className="text-md text-gray-400 my-2">Limited Access</span>
							<Button
								size="sm"
								variant="primary"
								className="rounded-full my-4 w-fit bg-gray-300 border-gray-300 self-center"
							>
								Current Plan
							</Button>
						</div>

						<div className="flex flex-col gap-2 p-5 bg-slate-50 rounded-xl">
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>⁠Lifetime access to influencer profile insights</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>
									⁠Create personalized data to your specific requirements
								</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>
									⁠Fast & efficient influencer data processing with Maple (AI
									KOL Specialist)
								</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>⁠Discover influencer brand partnerships.</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>⁠Explore influencer connections</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CircleX
									className="w-8 h-8 flex-shrink-0"
									fill="#bfbfbf"
									stroke="#FFF"
								/>
								<span>⁠Influencers’ rate card</span>
							</div>
						</div>
					</div>

					<div className="flex flex-col bg-white w-[40%] text-grey-800 pt-5 border border-orange-300 rounded-xl justify-between shadow-xl">
						<div className="flex flex-col w-full text-center ">
							<span className="text-2xl text-orange-500">Power Plan</span>
							<span className="text-3xl font-medium text-orange-500 my-2">
								<span className="line-through text-gray-400 font-light text-base">
									{formatToIDR(2_000_000)}
								</span>{" "}
								{formatToIDR(2_000_000)} / month
							</span>
							<span className="text-md text-gray-400 my-2">Billed Monthly</span>
							<Button
								size="sm"
								variant="primary"
								className="rounded-full my-4 w-fit  self-center"
							>
								Upgrade Now!
							</Button>
						</div>

						<div className="flex flex-col gap-2 p-5 bg-gradient-to-b from-orange-50 to-orange-100 rounded-xl ">
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>⁠Lifetime access to influencer profile insights</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>
									⁠Create personalized data to your specific requirements
								</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>
									⁠Fast & efficient influencer data processing with Maple (AI
									KOL Specialist)
								</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>⁠Discover influencer brand partnerships.</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>⁠Explore influencer connections</span>
							</div>
							<div className="flex items-center font-medium gap-1">
								<CheckCircle2Icon
									className="w-8 h-8 flex-shrink-0"
									fill="#FAA61A"
									stroke="#FFF"
								/>
								<span>⁠Influencers’ rate card</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Pricing;
