/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { cn, formatNumberCompact } from "@/common/helper";
import type { SocialMediaPlatform } from "@/types/master";
import {
	ChevronLeft,
	ChevronRight,
	HeartIcon,
	InfoIcon,
	MessageCircleMoreIcon,
	Play,
} from "lucide-react";
import React, { useCallback, useRef } from "react";
import type { publicApiType } from "react-horizontal-scrolling-menu";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import NoScrollbarDiv from "../ui/no-scrollbar-div";
import Tooltip from "../ui/tooltip";
import { dummyPost } from "./other/dummy-data";

export type TPostContent = {
	like: number;
	mediaThumbnail: string;
	comment: number;
	view: number;
	videoId?: string;
	shortcode?: string;
};
interface IPostContent {
	posts?: Array<TPostContent>;
	username: string;
	platform: SocialMediaPlatform;
	isDummy?: boolean;
	sectionOverlay?: boolean;
}

class DragDealer {
	public clicked: boolean;
	public dragging: boolean;
	public position: number;

	public constructor() {
		this.clicked = false;
		this.dragging = false;
		this.position = 0;
	}

	public dragStart = (event_: React.MouseEvent): void => {
		this.position = event_.clientX;
		this.clicked = true;
	};

	public dragStop = (): void => {
		window.requestAnimationFrame(() => {
			this.dragging = false;
			this.clicked = false;
		});
	};

	public dragMove = (
		event_: React.MouseEvent,
		callback: (posDiff: number) => void
	): void => {
		const newDiff = this.position - event_.clientX;

		const movedEnough = Math.abs(newDiff) > 5;

		if (this.clicked && movedEnough) {
			this.dragging = true;
		}

		if (this.dragging && movedEnough) {
			this.position = event_.clientX;
			callback(newDiff);
		}
	};
}

const LeftArrow = React.memo(() => {
	const visibility = React.useContext<publicApiType>(VisibilityContext);
	const isFirstItemVisible = visibility.useIsVisible("first", true);
	return (
		<div
			className={cn(
				"arrow arrow-left",
				isFirstItemVisible ? "opacity-0" : "opacity-100"
			)}
			onClick={() => {
				visibility.scrollPrev();
			}}
		>
			<div className="rounded-full w-10 h-10 bg-orange-500 bg-opacity-70 flex items-center justify-center hover:bg-opacity-90 cursor-pointer">
				<ChevronLeft color="#fff" />
			</div>
		</div>
	);
});

const RightArrow = React.memo(() => {
	const visibility = React.useContext<publicApiType>(VisibilityContext);
	const isLastItemVisible = visibility.useIsVisible("last", false);
	return (
		<div
			className={cn(
				"arrow arrow-right",
				isLastItemVisible ? "opacity-0" : "opacity-100"
			)}
			onClick={() => {
				visibility.scrollNext();
			}}
		>
			<div className="rounded-full w-10 h-10 bg-orange-500 bg-opacity-70 flex items-center justify-center hover:bg-opacity-90 cursor-pointer">
				<ChevronRight color="#fff" />
			</div>
		</div>
	);
});

function Card({
	itemId,
	post,
}: {
	itemId: string;
	post: TPostContent;
}): JSX.Element {
	return (
		<div
			data-cy={itemId}
			className="flex flex-col gap-2 h-[220px] w-[200px]"
			key={itemId}
		>
			<img
				src={post.mediaThumbnail}
				alt="img"
				className="object-cover w-[180px] h-[180px] rounded-xl"
			/>
			<div className="flex gap-2 text-sm text-grey-500 items-center">
				{post.view > 0 && (
					<div className="flex gap-1 items-center">
						<Play size={12} />
						<span>{formatNumberCompact(post.view, 1)}</span>
					</div>
				)}
				{post.like > 0 && (
					<div className="flex gap-1 items-center">
						<HeartIcon size={12} />
						<span>{formatNumberCompact(post.like, 1)}</span>
					</div>
				)}
				<div className="flex gap-1 items-center">
					<MessageCircleMoreIcon size={12} />
					<span>{formatNumberCompact(post.comment, 1)}</span>
				</div>
			</div>
		</div>
	);
}

const onWheel = (apiObject: publicApiType, event_: React.WheelEvent): void => {
	const isTouchpad =
		Math.abs(event_.deltaX) !== 0 || Math.abs(event_.deltaY) < 15;

	if (isTouchpad) {
		event_.stopPropagation();
		return;
	}

	if (event_.deltaY < 0) {
		apiObject.scrollNext();
	} else {
		apiObject.scrollPrev();
	}
};

const tooltip = [
	"The most popular and highly engaged content from recent posts.",
];
const PostContent: React.FC<IPostContent> = ({
	posts,
	username,
	platform,
	isDummy = false,
	sectionOverlay,
}) => {
	if (isDummy) {
		posts = dummyPost;
	}
	const dragState = useRef(new DragDealer());

	const onMouseDown = useCallback(
		() => dragState.current.dragStart,
		[dragState]
	);
	const onMouseUp = useCallback(() => dragState.current.dragStop, [dragState]);

	if (!posts) return <div></div>;

	return (
		<div className="flex flex-col p-2 sm:p-4 gap-4 bg-white rounded-xl w-full">
			<Tooltip tooltipContent={tooltip[0]}>
				<div className="flex items-center text-grey-500 gap-2">
					<span>Top Recent Content</span>
					<InfoIcon size={16} />
				</div>
			</Tooltip>
			<NoScrollbarDiv onMouseLeave={dragState.current.dragStop}>
				<div
					className={`scroll-menu-container ${sectionOverlay ? "filter blur-sm" : ""}`}
				>
					<ScrollMenu
						LeftArrow={LeftArrow}
						RightArrow={RightArrow}
						onMouseDown={onMouseDown}
						onMouseUp={onMouseUp}
						// onMouseMove={handleDrag}
						onWheel={onWheel}
					>
						{posts
							.sort(
								(a, b) =>
									b.like + b.comment + b.view - (a.like + a.comment + a.view)
							)
							.map((post, index) => (
								<a
									href={
										platform === "tiktok"
											? `https://www.tiktok.com/@${username}/video/${post.videoId}`
											: `https://www.instagram.com/p/${post.shortcode}`
									}
									target="_blank"
									key={index}
								>
									<Card itemId={index.toString()} post={post} />
								</a>
							))}
					</ScrollMenu>
				</div>
			</NoScrollbarDiv>
		</div>
	);
};

export default PostContent;
