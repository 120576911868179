import { parseAndRoundNumber } from "@/common/helper";
import type { ChartOptions, ChartData } from "chart.js";

import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

type GenderChartParameter = {
	male: number;
	female: number;
};

export const defaultColorsList = [
	"#E68C24",
	"#14A085",
	"#359FD9",
	"#FAD162",
	"#9DCFEC",
	"#E68C24",
	"#14A085",
	"#359FD9",
	"#FAD162",
	"#9DCFEC",
];

const DoughnutCart: React.FC<GenderChartParameter> = ({
	male,
	female,
}): React.ReactElement => {
	const options: ChartOptions<"doughnut"> = {
		plugins: {
			title: {
				display: false,
				text: "Gender",
			},
			legend: {
				position: "bottom",
				labels: {
					usePointStyle: true,
					pointStyle: "circle",
					padding: 20,
					generateLabels: (chart) => {
						if (chart && chart.data && chart.data.labels)
							return chart.data.labels.map((l, index) => {
								return {
									datasetIndex: index,
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									text: `${l as string} (${parseAndRoundNumber(chart.data["datasets"][0]?.data[index]?.toString() ?? "0")}%)`,
									fontColor: "rgb(91, 92, 94)",
									fillStyle: defaultColorsList[index],
									strokeStyle: defaultColorsList[index],
									hidden: chart.getDatasetMeta(index).hidden,
									index,
								};
							});
						return [
							{
								text: "",
							},
						];
					},
				},
			},

			tooltip: {
				callbacks: {
					label: function (context) {
						let label = context.dataset.label || "";

						if (label) {
							label += ": ";
						}
						if (context.parsed !== null) {
							label += parseAndRoundNumber(context.parsed.toString()) + "%";
						}
						return label;
					},
				},
			},
		},
	};

	const data: ChartData<"doughnut"> = {
		labels: ["Male", "Female"],
		datasets: [
			{
				// label: "Number",
				data: [male, female],
				backgroundColor: ["#E68C24", "#14A085"],
				borderColor: ["#E68C24", "#14A085"],
				borderWidth: 1,
			},
		],
	};

	return <Doughnut options={options} data={data} />;
};

export default DoughnutCart;
