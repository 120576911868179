import { InfoIcon } from "lucide-react";
import Tooltip from "../ui/tooltip";

// import VerifiedIcon from "@/assets/icons/verified-icon.svg";
interface IContentAnalysis {
	interests?: Array<string>;
	topMentions?: Array<string>;
	topHashtags?: Array<string>;
}

const tooltip = [
	"Categories that represent the main interests and subjects covered in the content.",
];
const ContentAnalysis: React.FC<IContentAnalysis> = ({
	interests = [
		"Fashion & Beauty",
		"Food & Travel",
		"Lifestyle & Home Decor",
		"Business & Entrepreneurship",
	],
}) => {
	return (
		<div className="flex flex-wrap gap-3 w-full">
			<div className="flex flex-1 flex-col sm:p-4 gap-4 bg-white rounded-xl">
				<Tooltip tooltipContent={tooltip[0]}>
					<div className="flex items-center text-grey-500 gap-2">
						<span>Topics</span>
						<InfoIcon size={16} />
					</div>
				</Tooltip>
				<ul className="flex flex-col p-3 sm:p-5 gap-3 sm:gap-5 w-full bg-grey-100 text-grey-500 rounded-lg">
					{interests.map((interest, index) => (
						<li key={index}>{interest}</li>
					))}
				</ul>
			</div>

			{/* <div className="flex flex-1 flex-col p-4 gap-4 bg-white rounded-xl">
					<span className="text-grey-500 font-semibold">Top Mention</span>

					<ul className="flex flex-col py-2 px-5 gap-5 bg-grey-100 text-grey-500  rounded-lg">
						{topMentions.map((mention) => (
							<li className="flex items-center gap-1">
								<span className="font-semibold">Taylor</span>
								<img src={VerifiedIcon} />
								<span>@{mention}</span>
							</li>
						))}
					</ul>
				</div>

				<div className="flex flex-1 flex-col p-4 gap-4 bg-white rounded-xl">
					<span className="text-grey-500 font-semibold">Top 5 Hashtag</span>

					<ul className="flex flex-col py-2 px-5 gap-5 bg-grey-100 text-grey-500  rounded-lg">
						{topHashtags.map((hashtag) => (
							<li>#{hashtag}</li>
						))}
					</ul>
				</div> */}
		</div>
	);
};

export default ContentAnalysis;
