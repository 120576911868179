import * as React from "react";
import { cn } from "../../common/helper";
import { Controller, useFormContext } from "react-hook-form";
import Label from "./label";
import InputMask from "react-input-mask";
import { NumericFormat } from "react-number-format";

export interface InputProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	mask?: string;
	isNumeric?: boolean;
	suffixIcon?: React.ReactNode; // ✅ New prop for suffix icon
	prefixIcon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type,
			mask,
			isNumeric,
			value,
			defaultValue,
			suffixIcon,
			prefixIcon,
			...props
		},
		ref
	) => {
		if (isNumeric) {
			let formattedValue: string = Array.isArray(value)
				? (value as Array<string>).join("")
				: typeof value === "string"
					? value
					: String(value ?? "");
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
			formattedValue = formattedValue.replaceAll(".", "") ?? "";
			const formattedDefaultValue: string | number | null = Array.isArray(
				defaultValue
			)
				? defaultValue.join("")
				: ((defaultValue as string | number | null) ?? null);
			return (
				<div className="relative group">
					{/* ✅ Prefix Icon (Changes Color on Focus) */}
					{prefixIcon && (
						<div className="absolute inset-y-0 left-3 flex items-center text-gray-500 group-focus-within:text-orange-500">
							{prefixIcon}
						</div>
					)}
					{/* ✅ Input with `peer` to detect focus */}
					<NumericFormat
						thousandSeparator="."
						decimalSeparator=","
						allowNegative={false}
						valueIsNumericString={true}
						allowLeadingZeros={false}
						value={formattedValue}
						defaultValue={formattedDefaultValue}
						thousandsGroupStyle="thousand"
						displayType="input"
						getInputRef={ref}
						className={cn(
							"border-input placeholder:text-muted-foreground focus:ring focus:ring-orange-500 focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1 shadow-sm transition-colors peer file:border-0 file:bg-transparent file: file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
							prefixIcon && "pl-10",
							suffixIcon && "pr-10",
							className
						)}
						onKeyDown={(event_) => {
							if (event_.key === "." || event_.key === ",") {
								event_.preventDefault(); // 🚫 Block dot and comma input
							}
						}}
						onPaste={(event_) => {
							event_.preventDefault(); // 🚫 Prevent pasting bad values
							const pastedData: string = event_.clipboardData.getData("text"); // Get pasted text
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
							const cleanData = pastedData.replaceAll(/[\s,.]/g, ""); // Remove dots, commas, and spaces
							if (!Number.isNaN(Number(cleanData))) {
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								event_.currentTarget.value = cleanData; // ✅ Set clean value
								// Trigger change event with a proper synthetic event
								props.onChange?.({
									...event_,
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									target: { ...event_.currentTarget, value: cleanData },
								} as React.ChangeEvent<HTMLInputElement>);
							}
						}}
						{...props}
					/>
					{/* ✅ Suffix Icon */}
					{suffixIcon && (
						<div className="absolute inset-y-0 right-3 flex items-center text-gray-500">
							{suffixIcon}
						</div>
					)}
				</div>
			);
		}

		if (mask) {
			return (
				<div className="relative">
					{/* ✅ Prefix Icon - Changes color on focus */}
					{prefixIcon && (
						<div className="absolute inset-y-0 left-3 flex items-center text-gray-500 group-focus-within:text-orange-500">
							{prefixIcon}
						</div>
					)}

					{/* ✅ InputMask with `peer` to detect focus */}
					<InputMask
						mask={mask}
						value={value}
						{...props}
						className={cn(
							"border-input placeholder:text-muted-foreground focus:ring focus:ring-orange-500 focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1 shadow-sm transition-colors peer file:border-0 file:bg-transparent file: file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
							prefixIcon && "pl-10",
							suffixIcon && "pr-10",
							className
						)}
						alwaysShowMask={false}
						maskPlaceholder={props.placeholder}
					>
						{(inputProps) => <input ref={ref} {...inputProps} type={type} />}
					</InputMask>

					{/* ✅ Suffix Icon */}
					{suffixIcon && (
						<div className="absolute inset-y-0 right-3 flex items-center text-gray-500">
							{suffixIcon}
						</div>
					)}
				</div>
			);
		}

		return (
			<div className="relative group">
				{/* ✅ Prefix Icon - Now changes color on focus */}
				{prefixIcon && (
					<div className="absolute inset-y-0 left-3 flex items-center text-gray-500 group-focus-within:text-orange-500">
						{prefixIcon}
					</div>
				)}

				{/* ✅ Standard Input with `peer` */}
				<input
					type={type}
					value={value}
					className={cn(
						"border-input placeholder:text-muted-foreground focus:ring focus:ring-orange-500 focus-visible:ring-ring flex h-9 w-full rounded-md border bg-transparent px-3 py-1 shadow-sm transition-colors peer file:border-0 file:bg-transparent file: file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
						prefixIcon && "pl-10",
						suffixIcon && "pr-10",
						className
					)}
					ref={ref}
					{...props}
				/>

				{/* ✅ Suffix Icon */}
				{suffixIcon && (
					<div className="absolute inset-y-0 right-3 flex items-center text-gray-500">
						{suffixIcon}
					</div>
				)}
			</div>
		);
	}
);
Input.displayName = "Input";

interface IInputForm extends React.InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	label?: string;
	required?: boolean;
	prefixIcon?: React.ReactNode;
	suffixIcon?: React.ReactNode;
	inputClassname?: string;
	mask?: string;
	isNumeric?: boolean;
}

const InputForm: React.FC<IInputForm> = ({
	name = "",
	label = "",
	required = false,
	prefixIcon,
	suffixIcon,
	inputClassname = "",
	mask,
	isNumeric,
	...rest
}) => {
	const methods = useFormContext();

	return (
		<Controller
			control={methods.control}
			name={name}
			defaultValue=""
			render={({ field, fieldState }) => (
				<div className="flex flex-col gap-2">
					<div className="flex items-start gap-1">
						<Label className="text-[18px] text-grey-800">{label}</Label>
						{required && <Label className=" text-red-600">*</Label>}
					</div>
					<div>
						<div className="relative">
							<Input
								{...field}
								{...rest}
								mask={mask}
								isNumeric={isNumeric}
								suffixIcon={suffixIcon} // ✅ Pass suffixIcon to Input
								prefixIcon={prefixIcon} // ✅ Pass suffixIcon to Input
								className={cn(
									fieldState.invalid &&
										"ring-1 focus:ring-red-500 ring-red-500",
									inputClassname
								)}
							/>
						</div>
						{fieldState.invalid && (
							<small className="text-sm text-red-600">
								{fieldState.error?.message}
							</small>
						)}
					</div>
				</div>
			)}
		/>
	);
};

export { Input, InputForm };
