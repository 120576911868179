import type React from "react";
import { useState } from "react";

import AudienceDemography from "@/components/analytics/audience-demography";
import BrandMentions from "@/components/analytics/brand-mention";
import ContentAnalysis from "@/components/analytics/content-analysis";
import CreatorMention from "@/components/analytics/creator-mention";
import type { TCreatorPerformance } from "@/components/analytics/creator-performance";
import CreatorPerformance from "@/components/analytics/creator-performance";
import type { TCreatorSummary } from "@/components/analytics/creator-summary";
import CreatorSummary from "@/components/analytics/creator-summary";
import PostContent from "@/components/analytics/post-content";
import SectionOverlay from "@/components/ui/section-overlay";
import type { InstagramComplete, InstagramPublic } from "@/types/instagram";
import TopHashtags from "@/components/analytics/top-hashtags";
import { cn } from "@/common/helper";

type InstagramProfileProps = {
	profileData: InstagramPublic | InstagramComplete;
	overlay: boolean;
	displayType?: "public" | "analytics" | "er_calc";
	analyzeInfluencer: (username: string) => void;
	isDummy?: boolean;
};

const InstagramProfile: React.FC<InstagramProfileProps> = ({
	profileData,
	overlay,
	displayType = "public",
	analyzeInfluencer,
	isDummy = false,
}) => {
	const [isAnalyzing, setIsAnalyzing] = useState(false);
	let summaryData: TCreatorSummary = {
		...(profileData as InstagramPublic),
		city: "Jakarta Selatan",
		province: "DKI Jakarta",
		country: "Indonesia",
		gender: "Male",
	};
	let performanceData: TCreatorPerformance = profileData as InstagramPublic;

	let ageData, locationData, genderData, interestData, posts, networks;
	let topHashtags: Array<string> = [];
	if (displayType === "analytics") {
		const igComplete = profileData as InstagramComplete;
		summaryData = {
			...igComplete.instagramMetric,
			city: igComplete.instagramMetric.instagramEstimatedMetric.city,
			province: igComplete.instagramMetric.instagramEstimatedMetric.province,
			country: igComplete.instagramMetric.instagramEstimatedMetric.country,
			gender: igComplete.instagramMetric.instagramEstimatedMetric.gender,
		};
		performanceData = igComplete.instagramMetric;

		ageData =
			igComplete.instagramMetric.instagramEstimatedMetric.audienceAges.map(
				(x) => x.value
			);
		locationData =
			igComplete.instagramMetric.instagramEstimatedMetric.audienceLocations;
		genderData = {
			male: igComplete.instagramMetric.instagramEstimatedMetric
				.audienceGenderMale,
			female:
				igComplete.instagramMetric.instagramEstimatedMetric
					.audienceGenderFemale,
		};
		interestData =
			igComplete.instagramMetric.instagramEstimatedMetric.interests;

		posts = igComplete.instagramMetric.instagramPosts ?? [];
		performanceData.postCount = posts.length;
		performanceData.videoCount = posts.filter((x) => x.isVideo).length;
		networks = igComplete.instagramMetric.mentionsOtherNetworks;
		topHashtags = igComplete.topHashtags;
	} else if (displayType === "er_calc") {
		const igComplete = profileData as InstagramComplete;
		summaryData = {
			...igComplete.instagramMetric,
			city: "Jakarta Selatan",
			province: "DKI Jakarta",
			country: "Indonesia",
			gender: "Male",
		};
		performanceData = igComplete.instagramMetric;
	}

	return (
		<div className="flex flex-col justify-start items-center my-4">
			<div className="flex flex-col max-w-screen-2xl w-full my-4">
				<CreatorSummary
					profileData={summaryData}
					blur={displayType == "public"}
					platform="instagram"
					isDummy={isDummy}
				></CreatorSummary>
			</div>

			<div className="mt-4 flex justify-center w-full max-w-screen-2xl bg-grey-100">
				{/* Content */}
				<div className="flex flex-col gap-8 p-2 sm:p-8 w-full max-w-screen-2xl">
					<div className="relative flex flex-col gap-4 max-w-screen-2xl ">
						{/* Creator Performance */}
						<CreatorPerformance
							profileData={performanceData}
							isDummy={isDummy}
							platform="instagram"
						></CreatorPerformance>
					</div>

					{/* Audience Demography */}

					<SectionOverlay
						overlay={overlay}
						isAnalyzing={isAnalyzing}
						setIsAnalyzing={setIsAnalyzing}
						displayType={displayType}
						analyzeInfluencer={() => {
							analyzeInfluencer(summaryData.username);
						}}
						containerClassName={cn(
							"flex flex-col gap-10",
							displayType == "public" || displayType == "er_calc" ? "mt-10" : ""
						)}
					>
						<AudienceDemography
							ageData={ageData}
							locationData={locationData}
							genderData={genderData}
						></AudienceDemography>

						<div className="flex flex-col gap-4">
							<div className="flex items-center gap-2">
								<div className="h-4 border-l-4 border-orange-500 rounded-3xl"></div>
								<span className="text-lg font-semibold">Content Analysis</span>
							</div>

							<div className="flex flex-row md:flex-row gap-4 flex-wrap">
								<div className="flex w-full lg:w-[calc(33.33%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-2 bg-white rounded-lg">
									<ContentAnalysis interests={interestData}></ContentAnalysis>
								</div>
								<div className="flex w-full lg:w-[calc(66.66%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-2 bg-white rounded-lg">
									<PostContent
										posts={posts}
										platform="instagram"
										username={summaryData.username}
										isDummy={
											displayType == "public" || displayType == "er_calc"
										}
										sectionOverlay={overlay}
									></PostContent>
								</div>
							</div>
						</div>

						<TopHashtags
							hashtags={topHashtags}
							platform="instagram"
							isDummy={displayType == "public" || displayType == "er_calc"}
						></TopHashtags>
						<BrandMentions
							networks={(networks || []).filter(
								(predicate) =>
									predicate.targetInstagramMetric.isBrandAccount === "TRUE"
							)}
							isDummy={displayType == "public" || displayType == "er_calc"}
							platform="instagram"
						></BrandMentions>
						{/* Creator Network */}

						<CreatorMention
							networks={(networks || []).filter(
								(predicate) =>
									predicate.targetInstagramMetric.isBrandAccount === "FALSE"
							)}
							isDummy={displayType == "public" || displayType == "er_calc"}
							platform="instagram"
						></CreatorMention>
					</SectionOverlay>
					{/* Creator Lookalike */}
					{/* <CreatorLookalike></CreatorLookalike> */}
				</div>
			</div>
		</div>
	);
};

export default InstagramProfile;
