import { openInNewTab } from "@/common/helper";
import { Hash } from "lucide-react";
import Tooltip from "../ui/tooltip";
import { dummyHashtags } from "./other/dummy-data";

interface ITopHashtags {
	hashtags: Array<string>;
	isDummy?: boolean;
	platform: "instagram" | "tiktok";
}

const TopHashtags: React.FC<ITopHashtags> = ({
	hashtags,
	platform,
	isDummy,
}) => {
	if (isDummy) {
		hashtags = dummyHashtags;
	}
	return (
		<div className="flex flex-col gap-4">
			<Tooltip tooltipContent="The most frequently used hashtag in the creator’s recent posts.">
				<div className="flex items-center gap-2">
					<div className="h-4 border-l-4 border-orange-500 rounded-3xl"></div>
					<span className="text-lg font-semibold">Top Hashtags</span>
				</div>
			</Tooltip>
			<div className="flex flex-wrap flex-row gap-2 bg-white p-4 rounded-xl">
				{hashtags.length === 0 && (
					<span className="text-gray-500">No hashtags found</span>
				)}
				{hashtags.map((hashtag, index) => {
					return (
						<span
							key={index}
							className="flex flex-row items-center gap-1 border border-gray-200 p-1 px-2 rounded-full cursor-pointer hover:border-orange-500"
							onClick={() => {
								if (platform === "instagram") {
									openInNewTab(
										`https://www.instagram.com/explore/tags/${hashtag.replace("#", "")}`
									);
								} else if (platform === "tiktok") {
									openInNewTab(
										`https://www.tiktok.com/tag/${hashtag.replace("#", "")}`
									);
								}
							}}
						>
							<Hash className="text-orange-600" size={20}></Hash>
							<span>{hashtag.replace("#", "")}</span>
						</span>
					);
				})}
			</div>
		</div>
	);
};

export default TopHashtags;
