/* eslint-disable unicorn/filename-case */
import EditProfile from "@/pages/settings/my-account/edit-profile";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/settings/my-account_/edit-profile")({
	component: EditProfile,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
