import { openInNewTab } from "@/common/helper";
import type { MentionsOtherInstagramNetworks } from "@/types/instagram";
import type { MentionsOtherTiktokNetworks } from "@/types/tiktok";
import BetaTag from "../ui/beta";
import Tooltip from "../ui/tooltip";
import {
	dummyBrandMentions,
	dummyBrandMentionsTikTok,
} from "./other/dummy-data";

interface IBrandMentions {
	networks: Array<MentionsOtherInstagramNetworks | MentionsOtherTiktokNetworks>;
	isDummy?: boolean;
	platform: "instagram" | "tiktok";
}

const BrandMentions: React.FC<IBrandMentions> = ({
	networks,
	platform,
	isDummy,
}) => {
	if (isDummy) {
		networks =
			platform == "instagram"
				? (dummyBrandMentions as Array<MentionsOtherInstagramNetworks>)
				: (dummyBrandMentionsTikTok as Array<MentionsOtherTiktokNetworks>);
	}
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center gap-2">
				<Tooltip tooltipContent="The most frequently mentioned brand account in the creator’s recent posts.">
					<div className="flex items-center gap-2">
						<div className="h-4 border-l-4 border-orange-500 rounded-3xl"></div>
						<span className="text-lg font-semibold">Brand Mentions</span>
					</div>
				</Tooltip>
				<BetaTag />
			</div>
			<div className="flex flex-wrap flex-row gap-2 bg-white p-4 rounded-xl">
				{networks.length === 0 && (
					<span className="text-gray-500">No brand mentions found</span>
				)}
				{networks.map((network, index) => {
					const isInstagram = platform === "instagram";
					const targetMetric = isInstagram
						? (network as MentionsOtherInstagramNetworks)?.targetInstagramMetric
						: (network as MentionsOtherTiktokNetworks)?.targetTiktokMetric;

					if (!targetMetric) return null; // Prevents errors if undefined

					return (
						<span
							key={index}
							className="flex flex-row items-center gap-2 border border-gray-200 hover:border-orange-500 p-1 pr-4 rounded-full cursor-pointer"
							onClick={() => {
								openInNewTab(
									platform === "instagram"
										? "https://instagram.com/" + targetMetric.username
										: "https://tiktok.com/@" + targetMetric.username
								);
							}}
						>
							<img
								src={targetMetric.avatarUrl}
								alt={`${platform} avatar`}
								className="w-8 h-8 rounded-full object-cover"
							/>
							<span className="mt-0.5">@{targetMetric.username}</span>
						</span>
					);
				})}
			</div>
		</div>
	);
};

export default BrandMentions;
