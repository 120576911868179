import Coins from "@/assets/icons/Coins.svg";
import { cn, formatDate, formatNumber, formatToIDR } from "@/common/helper";
import { useValidatePromo } from "@/hooks/payment-hooks";
import type { PlanType } from "@/types/master";
import { AxiosError } from "axios";
import { Check, InfoIcon, LoaderCircleIcon, TicketPercent } from "lucide-react";
import { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../ui/button";
import { InputForm } from "../ui/input";
import Modal from "../ui/modal";
import Tooltip from "../ui/tooltip";
import { useQuery } from "@tanstack/react-query";
import type { Promo } from "@/services/payment-services";
import { getPromoList } from "@/services/payment-services";

interface IBuyCoinsModal {
	isOpen: boolean;
	onClose: () => void;
	handleSubmit: (coins: number, promoCode?: string) => void;
}

const pricePerCoins = 1000;
const defaultCoins = [100, 200, 500, 1000, 2500, 5000];

const validatePromoSchema = yup.object({
	promoCodeValue: yup.string(),
});

const coinsStringtoInt = (inputCoins: string): number => {
	if (inputCoins === "") return 0;
	return Number.parseInt(
		inputCoins.replaceAll(".", "") === "" ? "0" : inputCoins.replaceAll(".", "")
	);
};
const BuyCoinsModal: React.FC<IBuyCoinsModal> = ({
	isOpen,
	onClose,
	handleSubmit,
}) => {
	// Wrapper function to handle the promise returned by handleSubmit

	const methods = useForm({});
	const [promoAmount, setPromoAmount] = useState(0);
	const [validatePromoIsLoading, setValidatePromoIsLoading] = useState(false);
	const [promoMessage, setPromoMessage] = useState("");
	const [promoCode, setPromoCode] = useState<string | undefined>();
	const [inputCoins, setInputCoins] = useState("");
	const [agree, setAgree] = useState(false);
	const [step, setStep] = useState(0);
	const [promotions, setPromotions] = useState<Array<Promo>>([]);

	const { isPending: isPromoListPending } = useQuery({
		queryKey: [`get-promo-list`],
		queryFn: async () => {
			const resp = await getPromoList();
			setPromotions(resp);
			return resp;
		},
		retry: 1,

		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		setPromoCode("");
		setPromoMessage("");
		setPromoAmount(0);
	}, [inputCoins]);

	const subtotalPrice =
		Number.parseInt(inputCoins === "" ? "0" : inputCoins) * pricePerCoins;
	const taxPrice = (subtotalPrice - promoAmount) * 0.11;

	const validatePromo = useValidatePromo();
	const planType: PlanType = "COINS";

	const handleSubmitStep = (): void => {
		if (step === 0) {
			setStep(1);
		} else {
			handleSubmit(
				Number.parseInt(inputCoins === "" ? "0" : inputCoins),
				promoAmount > 0 ? promoCode : undefined
			);
			setStep(0);
		}
	};
	const validatePromoSubmit: SubmitHandler<
		yup.InferType<typeof validatePromoSchema>
	> = ({ promoCodeValue }) => {
		if (!promoCodeValue) return;

		const payload: { code: string; coins: number; type: PlanType } = {
			code: promoCodeValue.toUpperCase(), // ✅ Uses function argument, not state
			coins: coinsStringtoInt(inputCoins),
			type: planType,
		};

		validatePromo.mutate(payload, {
			onSuccess: (data) => {
				setPromoAmount(data.promoAmount);
				setPromoMessage(
					`Congratulations 🎉  ! You will receive ${formatToIDR(data.promoAmount)} discount!`
				);
			},
			onError: (error: unknown) => {
				setPromoAmount(0);
				if (error instanceof AxiosError) {
					console.error("error", error);
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					setPromoMessage(error.response?.data.message);
				} else {
					console.error("unexpected error:", error);
				}
			},
			onSettled: () => {
				setValidatePromoIsLoading(false);
			},
		});
	};

	const handleValidatePromo = (code: string): void => {
		if (!code) return;

		methods
			.handleSubmit(() => validatePromoSubmit({ promoCodeValue: code }))()
			.catch((error) => {
				console.error(error);
			});
	};
	const renderStep0 = (): JSX.Element => {
		return (
			<div className="flex flex-col w-full pb-4">
				<Tooltip tooltipContent="5 Coins are required for full analysis, 1 Coin is required for engagement rate analysis">
					<div className="flex flex-row items-center gap-2 text-grey-500 px-5">
						<span className="text-grey-500 mb-1">Select Coins Amount</span>
						<InfoIcon size={16} />
					</div>
				</Tooltip>
				<div className="grid grid-cols-2 md:grid-cols-3 gap-4 my-4 px-5">
					{defaultCoins.map((coin) => (
						<div
							key={coin}
							className={cn(
								"flex flex-col items-center justify-center p-4 bg-white border border-grey-100 rounded-md shadow-md cursor-pointer",
								coin.toString() === inputCoins
									? "border-orange-500"
									: "border-grey-100"
							)}
							onClick={() => {
								setInputCoins(coin.toString());
							}}
						>
							<div className="flex flex-row items-center justify-center">
								<img src={Coins} alt="icons" className="h-6 w-6 mr-2" />
								<span className="text-3xl text-orange-500 font-semibold">
									{formatNumber(coin)}
								</span>
							</div>
							<span className="text-grey-500">
								{formatToIDR(coin * pricePerCoins)}
							</span>
						</div>
					))}
				</div>
				<FormProvider {...methods}>
					{/* Custom Input Coins */}
					<div className="flex flex-col gap-1 px-5">
						<span className="text-grey-500 mb-1">Or enter coins</span>
						<div className="flex flex-row items-center gap-4 w-full justify-between">
							<div className="flex-1">
								<InputForm
									type="text"
									className="flex-1 p-2 border border-grey-200 rounded-md"
									placeholder="Enter Coins (e.g.: 1.000, 2.500)"
									value={inputCoins}
									isNumeric={true}
									onChange={(event) => {
										setInputCoins(event.target.value.toString());
									}}
									prefixIcon={
										<img
											src={Coins}
											alt="coins"
											className="min-w-5 max-w-5 min-h-5 max-h-5 mr-1"
										/>
									}
								/>
							</div>
							<div className="flex-1 center mt-1 font-medium text-lg text-grey-500">
								{formatToIDR(coinsStringtoInt(inputCoins) * pricePerCoins)}
							</div>
						</div>
					</div>
					<div className="flex flex-row gap-4 center px-5">
						<div className="flex-1">
							<InputForm
								type="text"
								placeholder="Promo Code"
								value={promoCode}
								onChange={(event) => {
									setPromoCode(event.target.value.toUpperCase());
									setPromoMessage("");
									setPromoAmount(0);
								}}
								inputClassname={promoCode == "" ? "normal-case" : "uppercase"}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										handleValidatePromo(event.currentTarget.value); // ✅ Uses latest input value
									}
								}}
								prefixIcon={<TicketPercent></TicketPercent>}
							/>
						</div>
						<div className="flex-1">
							<Button
								size="md"
								variant="primary"
								className="max-w-24 lg:max-w-28 h-[41px] mt-2"
								onClick={() => {
									promoCode && handleValidatePromo(promoCode);
								}}
								disabled={promoCode == "" || validatePromoIsLoading}
								startIcon={
									validatePromoIsLoading && (
										<LoaderCircleIcon className="animate-spin" />
									)
								}
							>
								Apply
							</Button>
						</div>
					</div>
					{promoMessage && promoMessage.length > 0 && (
						<span
							className={cn(
								"mt-2 text-sm px-5",
								promoAmount == 0 ? "text-red-400" : "text-green-400"
							)}
						>
							{promoMessage}
						</span>
					)}
				</FormProvider>
				<div className="overflow-x-auto w-full px-6">
					<div className="flex space-x-4 whitespace-nowrap e py-4 ml-[-8px]">
						{isPromoListPending && (
							<LoaderCircleIcon className="animate-spin" />
						)}
						{promotions.map((promo, index) => (
							<div
								key={index}
								className={cn(
									`relative flex-none w-60 border border-gray-300 rounded-lg shadow-md bg-white overflow-visible pb-2
			before:absolute before:w-6 before:h-6 before:bg-white before:border before:border-gray-300
			before:rounded-full before:-left-3 before:top-1/2 before:-translate-y-1/2 before:[clip-path:circle(70%_at_100%_50%)]
			after:absolute after:w-6 after:h-6 after:bg-white after:border after:border-gray-300
			after:rounded-full after:-right-3 after:top-1/2 after:-translate-y-1/2 after:[clip-path:circle(70%_at_0_50%)]`,
									promo.code === promoCode &&
										"border-2 border-orange-500 after:border-2 after:border-orange-500 before:border-2 before:border-orange-500"
								)}
								onClick={() => {
									setPromoMessage("");
									setPromoAmount(0);
									setPromoCode(promo.code.toUpperCase());
									handleValidatePromo(promo.code.toUpperCase()); // ✅ Uses the latest promo code
								}}
							>
								{/* Header */}
								<div className="bg-orange-100 px-4 py-2 text-sm font-bold text-gray-900 rounded-t-lg mb-2 border-b border-gray-300 border-dashed">
									{promo.code}
								</div>

								{/* Content */}
								<div className="px-4">
									<p className="text-gray-900 font-semibold">{promo.title}</p>
									<p className="text-gray-500 text-sm mt-1">
										Valid Until {formatDate(promo.endDate)}
									</p>
									<p className="text-gray-500 text-sm mt-1">
										Min. Purchase {formatToIDR(promo.minTopUp)}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};
	const renderStep1 = (): JSX.Element => {
		return (
			<div className="w-full lg:min-w-[400px] text-grey-800 px-5">
				<div className="mb-4 border-grey-150 border rounded-xl p-4">
					<div className="flex justify-between mb-2">
						<span>
							Purchase Amount ({formatNumber(coinsStringtoInt(inputCoins))}{" "}
							Coins)
						</span>
						<span>{formatToIDR(subtotalPrice)}</span>
					</div>
					{promoAmount && promoAmount > 0 ? (
						<div className="flex justify-between mb-2">
							<span>Discount</span>
							<span className="text-green-400 font-semibold">
								- {formatToIDR(promoAmount)}
							</span>
						</div>
					) : null}
					<div className="flex justify-between mb-2">
						<span>VAT 11%</span>
						<span>{formatToIDR(taxPrice)}</span>
					</div>
					<div className="flex justify-between border-t border-grey-150 pt-2">
						<span className="font-semibold">Total</span>
						<span className="font-semibold">
							{formatToIDR(subtotalPrice + taxPrice - promoAmount)}
						</span>
					</div>
				</div>

				<div className="mb-4 mt-4">
					<label className="flex items-center">
						<input
							type="checkbox"
							className="hidden"
							checked={agree}
							onChange={() => {
								setAgree(!agree);
							}}
						/>

						<span
							className={`w-4 h-4 inline-block mr-2 border-2 rounded-sm ${
								agree ? "bg-orange-500 border-orange-500" : "border-orange-500"
							} flex justify-center items-center`}
						>
							{agree && <Check className="w-3 h-3 text-white" />}
						</span>
						<span>
							Agree to our{" "}
							<a
								href="/terms-and-conditions"
								target="_blank"
								className="underline"
							>
								terms & conditions
							</a>{" "}
							and{" "}
							<a href="/privacy-policy" target="_blank" className="underline">
								privacy policy
							</a>
							.
						</span>
					</label>
				</div>
			</div>
		);
	};
	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				setStep(0);
				onClose();
			}}
			title="Purchase Analytics Coins"
			withTitleIcon={false}
			hasCancelButton={step === 1}
			onCancel={() => {
				if (step === 1) {
					setStep(0);
				} else {
					onClose();
				}
			}}
			submitComponent={
				<Button
					size="md"
					variant="primary"
					onClick={handleSubmitStep}
					disabled={step == 0 ? inputCoins === "" : step == 1 ? !agree : true}
				>
					{step === 0 ? "Continue" : "Buy Coins"}
				</Button>
			}
			cancelText={step === 0 ? "Cancel" : "Back"}
			customContainerStyle="px-0"
			customFooterStyle="px-5"
		>
			{step === 0 ? renderStep0() : renderStep1()}
		</Modal>
	);
};

export default BuyCoinsModal;
