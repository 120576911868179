import Coins from "@/assets/icons/Coins.svg";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import { cn } from "@/common/helper";
import type { FunctionComponent } from "@/common/types";
import tokenServices from "@/services/token-services";
import type { SearchType } from "@/types/instagram";
import type {
	AnalyticsSource,
	SocialMediaPlatform,
	User,
} from "@/types/master";
import { Listbox, Transition } from "@headlessui/react";
import {
	ChartSpline,
	ChevronDownIcon,
	InfoIcon,
	PercentCircle,
	SearchIcon,
} from "lucide-react";
import type React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import Modal from "../ui/modal";
import { useNavigate } from "@tanstack/react-router";

interface ISearchBar {
	onSearchUser?: (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	) => void;
	onSearchUserBasic: (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	) => void;
	defaultValue?: string;
	defaultOption: SocialMediaPlatform;
	isLoading?: boolean;
	isStacked?: boolean;
	analyzeLocation?: "left" | "center" | "right";
}

const options: Array<{ name: string; value: SocialMediaPlatform }> = [
	{ name: "Instagram", value: "instagram" },
	{ name: "TikTok", value: "tiktok" },
];

const SearchBar = ({
	onSearchUser,
	onSearchUserBasic,
	defaultValue = "",
	defaultOption = "instagram",
	isLoading = false,
	isStacked = true,
	analyzeLocation = "center",
}: ISearchBar): FunctionComponent => {
	const [selected, setSelected] = useState(options[0]);
	const [searchQuery, setSearchQuery] = useState<string>(defaultValue);
	const [searchType, setSearchType] = useState<SearchType>("ANALYTICS");
	const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
	const previousIsLoadingRef = useRef<boolean>(isLoading);
	const navigate = useNavigate();

	useEffect(() => {
		if (previousIsLoadingRef.current && !isLoading) {
			setSearchQuery("");
		}
		previousIsLoadingRef.current = isLoading;
	}, [isLoading]);

	const userRef = useRef<User>({
		email: null,
		name: null,
		isEmailVerified: false,
	});
	useEffect(() => {
		const userData = tokenServices.getUser() || {
			email: null,
			name: null,
			isEmailVerified: false,
		};
		if (userData.email) {
			userRef.current = userData;
			setSearchType("ANALYTICS");
		} else {
			setSearchType("ER_CALC");
		}
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const query = event.target.value;
		setSearchQuery(query);
	};

	useEffect(() => {
		if (defaultValue) {
			setSearchQuery(defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (defaultOption) {
			setSelected(options.find((x) => x.value == defaultOption));
		}
	}, [defaultOption]);

	const handleSearch = (): void => {
		if (!selected) return;
		if (searchType == "ANALYTICS" && typeof onSearchUser === "function") {
			setSearchQuery("");
			onSearchUser(searchQuery, selected.value, "SEARCH");
		} else if (
			searchType == "ER_CALC" &&
			typeof onSearchUserBasic === "function"
		) {
			setSearchQuery("");
			onSearchUserBasic(searchQuery, selected.value, "SEARCH");
		}
	};

	const renderTag = (): JSX.Element => {
		return (
			<div
				className="mr-2 h-8 sm:w-auto flex items-center cursor-pointer shadow-none sm:shadow rounded-full"
				onClick={handleSearch}
			>
				<button className="flex justify-center items-center gap-1 sm:px-1 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 group hover:text-orange-700 rounded-full bg-orange-500 text-white font-normal shadow-md">
					<span
						className={cn(
							"hidden sm:block whitespace-nowrap pb-0.5 pr-1 pl-4 my-1",
							userRef.current && userRef.current.email ? "" : "block"
						)}
					>
						Analyze
					</span>
					{userRef.current && userRef.current.email ? (
						<div className="flex flex-row items-center justify-center rounded-full bg-orange-300 sm:bg-gray-50 px-2">
							<img
								src={Coins}
								alt="coins"
								className="min-w-5 max-w-5 min-h-5 max-h-5 mr-1"
							/>
							<span className="pb-0.5 group-hover:text-orange-500 w-2 text-orange-700 sm:text-orange-500">
								{searchType == "ANALYTICS" ? 5 : 1}
							</span>
						</div>
					) : (
						<span className="pr-2"></span>
					)}
				</button>
			</div>
		);
	};

	const renderAnalyzeType = (): JSX.Element => {
		return (
			<div
				className={cn(
					"flex items-center rounded-full border border-orange-500 my-2 h-10 bg-white w-full md:max-w-80",
					!isStacked && "md:h-[58px]"
				)}
			>
				<div
					className={cn(
						"flex justify-center items-center w-1/2 h-full rounded-full cursor-pointer gap-2",
						searchType === "ANALYTICS"
							? "bg-orange-500 text-white shadow"
							: "text-gray-500"
					)}
					onClick={() => {
						if (!userRef.current || !userRef.current.email) {
							setShowLoginModal(true);
							return;
						}
						setSearchType("ANALYTICS");
					}}
				>
					<ChartSpline></ChartSpline>
					<span className={cn("")}>Full Analysis</span>
				</div>
				<div
					className={cn(
						"flex justify-center items-center w-1/2 h-full rounded-full cursor-pointer gap-2",
						searchType === "ER_CALC"
							? "bg-orange-500 text-white  shadow"
							: "text-gray-500"
					)}
					onClick={() => {
						setSearchType("ER_CALC");
					}}
				>
					<PercentCircle></PercentCircle>
					<span className={cn("")}>Engagement Rate</span>
				</div>
			</div>
		);
	};

	return (
		<>
			<div
				className={cn(
					"flex w-full gap-2",
					isStacked ? "flex-col" : "flex-col md:flex-row",
					analyzeLocation === "left"
						? "items-start"
						: analyzeLocation === "center"
							? "items-center"
							: "items-end"
				)}
			>
				{renderAnalyzeType()}
				<div className="w-full flex items-center ring-1 rounded-full border transition-colors focus-visible:outline-none focus-visible:ring-1 ring-orange-500 shadow bg-white">
					<div className="min-w-[60px] sm:min-w-[150px] ">
						<Listbox value={selected} onChange={setSelected}>
							<div className="relative">
								<Listbox.Button
									className={`flex border-white justify-center items-center w-full
							cursor-pointer rounded-full bg-white h-12 py-2 pl-3
							text-left border-l border-y border-r-0 focus:outline-none`}
								>
									<span className="flex items-center gap-2 truncate">
										{selected?.name === "Instagram" ? (
											<img src={InstaIcon} alt="" />
										) : (
											<img src={TiktokIcon} alt="" />
										)}
										<span className="hidden sm:block">{selected?.name}</span>
									</span>
									<span className="pointer-events-none inset-y-0 right-0 flex items-center px-1">
										<ChevronDownIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</span>
								</Listbox.Button>
								<Transition
									as={Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Listbox.Options className="absolute mt-1 max-h-60 w-full min-w-[132px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none ring-orange-500">
										{options.map((option, optionIndex) => (
											<Listbox.Option
												key={optionIndex}
												className={({ active }) =>
													`cursor-pointer select-none py-2 px-2 sm:px-4 ${
														active
															? "bg-grey-100 text-gray-900"
															: "text-gray-900"
													}`
												}
												value={option}
											>
												{({ selected }) => (
													<div className={`flex items-center gap-2 px-2`}>
														{option.name === "Instagram" ? (
															<img src={InstaIcon} alt="icon" />
														) : (
															<img src={TiktokIcon} alt="icon" />
														)}
														<span
															className={`block truncate ${
																selected
																	? "font-medium"
																	: "font-normal text-grey-400"
															}`}
														>
															{option.name}
														</span>
													</div>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>

					<div className="h-7 border-l top-[11px] border-grey-150"></div>
					<SearchIcon
						className="top-4 w-10 mx-2 self-center"
						size={20}
						color="#BFBFBF"
					/>
					<input
						type="text"
						disabled={isLoading}
						className={cn(
							"border-none placeholder:text-muted-foreground flex h-12 w-full bg-transparent p-1  text-grey-800 disabled:cursor-not-allowed disabled:opacity-50 outline-none"
						)}
						placeholder="Enter Username Here"
						onChange={handleChange}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								selected ? handleSearch() : null;
							}
						}}
						value={searchQuery}
					/>
					{renderTag()}
				</div>
			</div>
			<Modal
				isOpen={showLoginModal}
				title="Please login to use full analysis feature"
				onClose={() => {
					setShowLoginModal(false);
				}}
				children={<></>}
				submitText="Login"
				icon={<InfoIcon />}
				onSubmit={() => {
					navigate({ to: "/login" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
		</>
	);
};

export default SearchBar;
