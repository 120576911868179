import type React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CircleXIcon, LoaderCircleIcon, LockKeyholeIcon } from "lucide-react";
import Button from "./button";
import { cn } from "@/common/helper";

interface IModal {
	children: React.ReactNode;
	isOpen: boolean;
	title?: string;
	description?: string;
	onClose: () => void;
	onSubmit?: () => void;
	onCancel?: () => void;
	isLoading?: boolean;
	withContent?: boolean;
	submitText?: string;
	cancelText?: string;
	icon?: JSX.Element;
	withTitleIcon?: boolean;
	hasCancelButton?: boolean;
	submitComponent?: JSX.Element;
	hasCloseIcon?: boolean;
	customContainerStyle?: string;
	customFooterStyle?: string;
}

const Modal: React.FC<IModal> = ({
	children,
	title,
	description,
	isOpen,
	onClose,
	onSubmit,
	isLoading = false,
	withContent = true,
	submitText = "Continue",
	cancelText = "Cancel",
	onCancel,
	icon = <LockKeyholeIcon />,
	hasCancelButton = true,
	withTitleIcon = true,
	submitComponent,
	hasCloseIcon = true,
	customContainerStyle,
	customFooterStyle,
}: IModal) => {
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog onClose={() => {}}>
				{/* Backdrop */}
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div
						className="fixed inset-0 bg-grey-800/30 z-10"
						aria-hidden="true"
					/>
				</Transition.Child>

				{/* Modal Container */}
				<div className="fixed inset-0 flex items-center justify-center p-4 z-20 bg-grey-500/50">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						{/* Modal Panel - Now Scrollable */}
						<Dialog.Panel className="sm:mx-auto w-screen sm:max-w-[440px] lg:max-w-[640px] bg-white rounded-lg shadow-lg overflow-hidden max-h-[85vh] flex flex-col">
							{/* Header */}
							<div className="flex flex-row w-full justify-between p-3 items-center">
								<div></div>
								{!withTitleIcon && title && (
									<Dialog.Title className="font-medium text-xl text-grey-800 self-center mx-2">
										{title}
									</Dialog.Title>
								)}
								{hasCloseIcon && (
									<CircleXIcon
										fill="#BFBFBF"
										stroke="#fff"
										className="cursor-pointer hover:fill-red-500 self-end mb-1"
										onClick={onClose}
									/>
								)}
							</div>

							{/* Scrollable Content */}
							<div className="overflow-y-auto py-2 flex-1">
								{withContent ? (
									<div
										className={cn(
											"flex flex-col justify-center items-center w-full",
											customContainerStyle
										)}
									>
										{withTitleIcon && (
											<div className="p-2 border-2 text-grey-400 rounded">
												{icon}
											</div>
										)}

										<div className="flex flex-col justify-center items-center">
											{withTitleIcon && title && (
												<Dialog.Title className="font-medium text-lg text-grey-800">
													{title}
												</Dialog.Title>
											)}
											{description && (
												<Dialog.Description className="text-sm my-2 text-grey-400">
													{description}
												</Dialog.Description>
											)}
										</div>

										{children}
									</div>
								) : (
									<>{children}</>
								)}
							</div>

							{/* Footer */}
							<div
								className={cn(
									"p-4 border-t border-gray-200 flex flex-col gap-1",
									customFooterStyle
								)}
							>
								{!submitComponent && (
									<Button
										size="md"
										variant="primary"
										onClick={onSubmit}
										disabled={isLoading}
										startIcon={
											isLoading && <LoaderCircleIcon className="animate-spin" />
										}
									>
										{submitText}
									</Button>
								)}
								{submitComponent}
								{hasCancelButton && (
									<Button
										type="button"
										size="md"
										variant="outline"
										onClick={(event_) => {
											event_.preventDefault();
											if (onCancel) onCancel();
											else onClose();
										}}
									>
										{cancelText}
									</Button>
								)}
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
