import { hasAnalyticCredit, hasERCredit } from "@/common/helper";
import type { FunctionComponent } from "@/common/types";
import FloatingButton from "@/components/analytics/other/floating-button";
import SearchBar from "@/components/forms/search-bar";
import { Layout } from "@/components/layout";
import { customToast } from "@/components/ui/custom-toast";
import Modal from "@/components/ui/modal";
import {
	useAnalyzeInstagram,
	useAnalyzeInstagramBasic,
	useAnalyzeTikTok,
	useAnalyzeTikTokBasic,
} from "@/hooks/social-media-hooks";
import InstagramProfile from "@/pages/analytics/instagram-profile";
import TikTokProfile from "@/pages/analytics/tiktok-profile";
import { Route } from "@/routes/discover_.$platform.$userSearchUUID";
import type { QueueAnalyzeResponse } from "@/services/master-services";
import { useBalanceStore } from "@/store/credit-stores";
import type { InstagramComplete } from "@/types/instagram";
import type { AnalyticsSource, SocialMediaPlatform } from "@/types/master";
import type { TikTokComplete } from "@/types/tiktok";
import type { DeferredPromise } from "@tanstack/react-router";
import { Await, useNavigate, useParams } from "@tanstack/react-router";
import type { AxiosError } from "axios";
import { InfoIcon, LoaderCircleIcon } from "lucide-react";
import { Suspense, useEffect, useState } from "react";
import toast from "react-hot-toast";

interface ErrorResponse {
	message: string;
}
const isAxiosError = (error: unknown): error is AxiosError<ErrorResponse> => {
	return (error as AxiosError<ErrorResponse>).isAxiosError !== undefined;
};

export const data = {
	labels: ["Male", "Female"],
	datasets: [
		{
			// label: "Number",
			data: [62.87, 37.13],
			backgroundColor: ["#E68C24", "#14A085"],
			borderColor: ["#E68C24", "#14A085"],
			borderWidth: 1,
		},
	],
};

type ProfileResponse = InstagramComplete | TikTokComplete;

function isTikTok(response: ProfileResponse): response is TikTokComplete {
	return (
		(response as TikTokComplete).tiktokMetric &&
		(response as TikTokComplete).tiktokMetric.id !== undefined
	);
}

function isInstagram(response: ProfileResponse): response is InstagramComplete {
	return (
		(response as InstagramComplete).instagramMetric &&
		(response as InstagramComplete).instagramMetric.id !== undefined
	);
}

type ProfileDataRoute = {
	data: DeferredPromise<TikTokComplete | InstagramComplete | null>;
};

function DiscoverDetail(): FunctionComponent {
	// const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { platform } = useParams({
		from: "/discover_/$platform/$userSearchUUID",
	});

	const balance = useBalanceStore((state) => state.balance);

	const [overlay, setOverlay] = useState(true);
	const [showPurchaseCredit, setShowPurchaseCredit] = useState(false);
	const [shouldNavigate, setShouldNavigate] = useState(false);

	const navigate = useNavigate();
	const analyzeInstagram = useAnalyzeInstagram();
	const analyzeTikTok = useAnalyzeTikTok();
	const analyzeInstagramBasic = useAnalyzeInstagramBasic();
	const analyzeTikTokBasic = useAnalyzeTikTokBasic();

	const { data: profileData }: ProfileDataRoute = Route.useLoaderData();

	const handleSearchUserBasic = (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	): void => {
		// console.log(searchQuery, selectedOption, "SEARCH ");

		if (hasERCredit(balance)) {
			const mutateOpt = {
				onSuccess: (data: QueueAnalyzeResponse): void => {
					if (data.status === "ON_PROGRESS") {
						customToast(
							{
								title: "Analysis is on progress",
								description: `The username ${searchQuery} you entered on ${selectedOption} is currently being analyzed.`,
							},
							"warning"
						);
						return;
					} else {
						if (data.error === "USERNAME INVALID") {
							customToast(
								{
									title: "Username Invalid",
									description: `Please enter only valid username on ${selectedOption}`,
								},
								"error"
							);
							return;
						} else
							customToast(
								{
									title: "Analysis is on process",
									description: `The analysis for username ${searchQuery} on ${selectedOption} is estimated to take 10 minutes. \n We will send a notification to your email once it is finished.`,
								},
								"success"
							);
						return;
					}
				},
				onError: (error: unknown): void => {
					if (isAxiosError(error)) {
						if (error.response?.status === 429) {
							setShowPurchaseCredit(true);
						} else {
							const errorMessage: string =
								typeof error.response?.data?.message === "string"
									? error.response.data.message
									: "An error occurred";
							toast.error(errorMessage);
						}
					} else {
						console.error("Unexpected error:", error);
					}
				},
			};
			if (selectedOption == "instagram")
				analyzeInstagramBasic.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
			else
				analyzeTikTokBasic.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
		}
	};

	useEffect(() => {
		if (shouldNavigate) {
			navigate({ to: "/" })
				.then(() => {
					toast.error("Influencer data expired. Please analyze again.");
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}, [shouldNavigate, navigate]);
	const handleSearchUser = (
		searchQuery: string,
		selectedOption: SocialMediaPlatform,
		analyticsSource: AnalyticsSource
	): void => {
		if (hasAnalyticCredit(balance)) {
			const mutateOpt = {
				onSuccess: (data: QueueAnalyzeResponse): void => {
					if (data.status === "ON_PROGRESS") {
						customToast(
							{
								title: "Analysis is on progress",
								description: `The username ${searchQuery} you entered on ${selectedOption} is currently being analyzed.`,
							},
							"warning"
						);
						return;
					} else {
						if (data.error === "USERNAME INVALID") {
							customToast(
								{
									title: "Username Invalid",
									description: `Please enter only valid username on ${selectedOption}`,
								},
								"error"
							);
							return;
						} else
							customToast(
								{
									title: "Analysis is on process",
									description: `The analysis for username ${searchQuery} on ${selectedOption} is estimated to take 10 minutes. \n We will send a notification to your email once it is finished.`,
								},
								"success"
							);
						return;
					}
				},
				onError: (error: unknown): void => {
					if (isAxiosError(error)) {
						const errorMessage: string =
							typeof error.response?.data?.message === "string"
								? error.response.data.message
								: "An error occurred";
						toast.error(errorMessage);
					} else {
						console.error("Unexpected error:", error);
					}
				},
			};
			if (selectedOption == "instagram")
				analyzeInstagram.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
			else
				analyzeTikTok.mutate(
					{
						username: searchQuery,
						analyticsSource,
					},
					mutateOpt
				);
		}
	};

	return (
		<Layout withSidebar={false}>
			<Modal
				isOpen={showPurchaseCredit}
				title="No analytics coins left, please add more coins"
				onClose={() => {
					setShowPurchaseCredit(false);
				}}
				children={<></>}
				submitText="Add Coins"
				icon={<InfoIcon></InfoIcon>}
				onSubmit={() => {
					navigate({ to: "/settings/my-plan" }).catch((error) => {
						console.error(error);
					});
				}}
			></Modal>
			{/* Header */}
			<div className="flex flex-col w-full">
				{/* Search */}
				<div className="flex flex-row w-full bg-gray-50 justify-center items-center py-2">
					<div className="w-full sm:w-screen-xl max-w-screen-xl self-center p-2">
						<SearchBar
							onSearchUserBasic={
								hasERCredit(balance)
									? handleSearchUserBasic
									: (): void => {
											setShowPurchaseCredit(true);
										}
							}
							onSearchUser={
								hasAnalyticCredit(balance) ? handleSearchUser : (): void => {}
							}
							defaultOption={platform as SocialMediaPlatform}
							isStacked={false}
						/>
					</div>
				</div>

				<Suspense
					fallback={
						<div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-white border opacity-75 z-50">
							<div className="flex justify-center items-center">
								<LoaderCircleIcon className="animate-spin" size={50} />{" "}
								<span className="mx-4">Preparing...</span>
							</div>
						</div>
					}
				>
					<Await promise={profileData}>
						{(data: InstagramComplete | TikTokComplete | null): JSX.Element => {
							if (!data) {
								setShouldNavigate(true);
								return <></>;
							}
							return (
								<div className="w-screen sm:w-screen-xl max-w-screen-xl self-center p-2">
									{data && isInstagram(data) && (
										<>
											<InstagramProfile
												profileData={data}
												overlay={
													data.instagramMetric.searchType == "ANALYTICS"
														? false
														: overlay
												}
												displayType={
													data.instagramMetric.searchType == "ANALYTICS"
														? "analytics"
														: "er_calc"
												}
												analyzeInfluencer={(username: string) => {
													hasAnalyticCredit(balance)
														? handleSearchUser(username, "instagram", "ER_CALC")
														: setShowPurchaseCredit(true);
												}}
											/>
											{data.instagramMetric.searchType == "ER_CALC" && (
												<FloatingButton
													overlay={overlay}
													setOverlay={setOverlay}
												></FloatingButton>
											)}
										</>
									)}

									{data && isTikTok(data) && (
										<>
											<TikTokProfile
												profileData={data}
												overlay={
													data.tiktokMetric.searchType == "ANALYTICS"
														? false
														: overlay
												}
												displayType={
													data.tiktokMetric.searchType == "ANALYTICS"
														? "analytics"
														: "er_calc"
												}
												analyzeInfluencer={(username: string) => {
													hasAnalyticCredit(balance)
														? handleSearchUser(username, "tiktok", "ER_CALC")
														: navigate({
																to: "/settings/my-plan",
															}).catch((error) => {
																console.error(error);
															});
												}}
											/>
											{data.tiktokMetric.searchType == "ER_CALC" && (
												<FloatingButton
													overlay={overlay}
													setOverlay={setOverlay}
												></FloatingButton>
											)}
										</>
									)}
								</div>
							);
						}}
					</Await>
				</Suspense>
			</div>
		</Layout>
	);
}

export default DiscoverDetail;
