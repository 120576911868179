import { useCheckPassword } from "@/hooks/auth-hooks";
import tokenServices from "@/services/token-services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import Modal from "../ui/modal";
import { InputForm } from "../ui/input";

interface ICheckPassword {
	isOpen: boolean;
	onClose: (isOpen: boolean) => void;
	href: string;
}

const checkPasswordSchema = yup.object({
	password: yup.string().required(),
});

const CheckPasswordModal: React.FC<ICheckPassword> = ({
	isOpen,
	onClose,
	href,
}) => {
	const methods = useForm({
		resolver: yupResolver(checkPasswordSchema),
		mode: "onChange",
	});

	const checkPassword = useCheckPassword();
	const navigate = useNavigate();

	const onSubmit: SubmitHandler<yup.InferType<typeof checkPasswordSchema>> = (
		data
	) => {
		const payload: { email: string; password: string } = {
			email: tokenServices.getUserEmail() || "",
			...data,
		};

		checkPassword.mutate(payload, {
			onSuccess: () => {
				navigate({ to: href }).catch((error) => {
					console.error(error);
				});
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					toast.error("Incorrect password");
				} else {
					console.error("unexpected error:", error);
				}
			},
		});
	};

	const handleSubmit = (): void => {
		methods
			.handleSubmit(onSubmit)()
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose(false);
				methods.reset();
			}}
			title="Enter your password"
			description="Password is required to edit"
			onSubmit={handleSubmit} // Use the wrapper function here
			isLoading={checkPassword.isPending}
		>
			<div className="w-full px-4">
				<FormProvider {...methods}>
					<InputForm
						type="password"
						name="password"
						label="Password"
						placeholder=""
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								handleSubmit();
							}
						}}
					/>
				</FormProvider>
			</div>
		</Modal>
	);
};

export default CheckPasswordModal;
