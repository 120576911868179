import MyPlan from "@/pages/settings/my-plan";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/settings/my-plan")({
	component: MyPlan,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
