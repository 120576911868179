import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import VerifiedIcon from "@/assets/icons/verified-icon.svg";
import Thumbs from "@/assets/images/person-thumb.jpeg";
import { cn, formatNumber, parseAndRoundNumber } from "@/common/helper";
import type { SocialMediaPlatform } from "@/types/master";
import { Copy, LinkIcon, LockKeyhole, MapPinIcon } from "lucide-react";
import toast from "react-hot-toast";
import Tooltip from "../ui/tooltip";

export type TCreatorSummary = {
	avatarUrl: string;
	name: string;
	username: string;
	follower: number;
	isVerified: boolean;
	city?: string;
	province?: string;
	country?: string;
	bio: string;
	following: number;
	post: number;
	avgErPercent: string;
	gender: string;
	externalUrls: Array<string>;
};
interface ICreatorSummary {
	profileData: TCreatorSummary;
	blur: boolean;
	platform: SocialMediaPlatform;
	isDummy: boolean;
}

const ensureProtocol = (url: string): string => {
	if (!/^https?:\/\//.test(url)) {
		return "http://" + url;
	}
	return url;
};
const constructLocation = (
	city: string,
	province: string,
	country: string
): string => {
	const locations: Array<string> = [];
	if (city && city.length > 0 && city !== "unknown") {
		locations.push(city);
	}
	if (province && province.length > 0 && province !== "unknown") {
		locations.push(province);
	}
	if (country && country.length > 0 && country !== "unknown") {
		locations.push(country);
	}
	return locations.join(", ");
};

const CreatorSummary: React.FC<ICreatorSummary> = ({
	profileData,
	blur,
	platform = "instagram",
	isDummy = false,
}) => {
	return (
		<div className="flex flex-wrap flex-row w-full gap-4">
			<div className="flex flex-col gap-4 mx-2 w-full">
				<div className="flex items-center gap-2 mb-2">
					<img
						src={platform == "instagram" ? InstaIcon : TiktokIcon}
						alt="icon"
					/>
					<span className="text-base sm:text-lg">
						{profileData.name ?? "-"}
					</span>
					<span className="text-base sm:text-lg text-grey-300 ml-2">
						@{profileData.username ?? "-"}
					</span>
					{profileData.isVerified && (
						<img src={VerifiedIcon} alt="verified-icon" className="w-[16px]" />
					)}
				</div>

				<div className="flex flex-col sm:flex-row gap-6">
					<div className="min-w-32 h-32 flex-row self-center sm:self-start">
						<img
							src={profileData.avatarUrl ?? Thumbs}
							alt=""
							className="w-32 h-32 object-cover rounded-full"
						/>
					</div>

					<div className="flex flex-col w-full gap-1">
						<div className="flex w-full items-center text-sm text-grey-300 gap-2 ">
							<MapPinIcon fill="#969696" stroke="#969696" size={16} />
							<span className={blur ? "bg-grey-100 blur" : ""}>
								{constructLocation(
									profileData.city ?? "",
									profileData.province ?? "",
									profileData.country ?? ""
								)}
							</span>
							<div className="h-4 border-l border-grey-100"></div>
							<span className={blur ? "bg-grey-100 blur" : "capitalize"}>
								{profileData.gender}
							</span>
						</div>

						<span className=" text-grey-300 my-2">
							{profileData.bio ?? "-"}
						</span>
						{profileData.externalUrls?.filter((url) => url.trim().length > 0)
							.length > 0 && (
							<div className="mb-2 flex flex-row text-primary-500">
								<LinkIcon size={16} className="mr-2 mt-2"></LinkIcon>
								<div className="flex flex-col">
									{profileData.externalUrls.map((url, index) => (
										<span key={index}>
											<a
												className=" underline truncate max-w-72 text-nowrap"
												target="_blank"
												rel="noopener noreferrer"
												href={ensureProtocol(url)}
											>
												{url}
											</a>
											{/* {index < profileData.externalUrls.length - 1 && (
											<span className="mr-1">, </span>
										)} */}
										</span>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="flex flex-row flex-wrap w-full gap-6">
				<div className="flex flex-col flex-1 py-2 px-5 gap-1 border rounded-xl">
					<span className="text-grey-300">Followers</span>

					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"font-semibold text-xl flex items-center group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.follower) {
									navigator.clipboard
										.writeText(formatNumber(profileData.follower, 0))
										.then(() => {
											toast.success("Follower has been copied to clipboard");
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData.follower ? (
								<>
									{formatNumber(profileData.follower, 0)}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 py-2 px-5 gap-1 border rounded-xl">
					<span className="text-grey-300">Following</span>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"font-semibold text-xl flex items-center group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.following) {
									navigator.clipboard
										.writeText(formatNumber(profileData.following, 0))
										.then(() => {
											toast.success("Following has been copied to clipboard");
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData.following ? (
								<>
									{formatNumber(profileData.following, 0)}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 py-2 px-5 gap-1 border rounded-xl">
					<span className="text-grey-300">Posts</span>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"font-semibold text-xl flex items-center group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.post) {
									navigator.clipboard
										.writeText(formatNumber(profileData.post, 0))
										.then(() => {
											toast.success("Total Post has been copied to clipboard");
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData.post ? (
								<>
									{formatNumber(profileData.post, 0)}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>

				<div className="flex flex-col flex-1 py-2 px-5 gap-1 border rounded-xl">
					<span className="text-grey-300 hidden sm:block">Engagement Rate</span>
					<span className="text-grey-300 block sm:hidden">Eng. Rate</span>
					<Tooltip tooltipContent={"Click to copy"}>
						<span
							className={cn(
								"font-semibold text-xl flex items-center group",
								isDummy ? "" : "hover:text-orange-500 hover:cursor-pointer"
							)}
							onClick={() => {
								if (!isDummy && profileData.avgErPercent) {
									navigator.clipboard
										.writeText(
											parseAndRoundNumber(profileData.avgErPercent) + "%"
										)
										.then(() => {
											toast.success(
												"Engagement Rate has been copied to clipboard"
											);
										})
										.catch((error) => {
											console.error(error);
										});
								}
							}}
						>
							{isDummy ? (
								<LockKeyhole size={24} color="#969696" />
							) : profileData ? (
								<>
									{parseAndRoundNumber(profileData.avgErPercent) + "%"}
									<Copy
										size={16}
										className={`ml-1 transition-opacity duration-300 opacity-0 ${
											isDummy ? "" : "group-hover:opacity-100"
										}`}
									/>
								</>
							) : (
								0
							)}
						</span>
					</Tooltip>
				</div>
			</div>
		</div>
	);
};

export default CreatorSummary;
