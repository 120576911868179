export const dummyPost = [
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3567763617921697791.png",
		like: 5829,
		view: 0,
		shortcode: "DGDPhuQyQf_",
		comment: 22,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3550984927749668559.png",
		like: 3066,
		view: 0,
		shortcode: "DFHofpVSP7P",
		comment: 23,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3549013772474135065.png",
		like: 3024,
		view: 0,
		shortcode: "DFAoTj7SjIZ",
		comment: 27,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3546696661785533874.png",
		like: 2804,
		view: 0,
		shortcode: "DE4ZdKbyl2y",
		comment: 31,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3556930239215689086.png",
		like: 2325,
		view: 0,
		shortcode: "DFcwTUHS91-",
		comment: 9,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3535740288771938139.png",
		like: 2073,
		view: 0,
		shortcode: "DEReQ86Sddb",
		comment: 19,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3550350851359445601.png",
		like: 2043,
		view: 0,
		shortcode: "DFFYUnrSRJh",
		comment: 13,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3563432023035556774.png",
		like: 1859,
		view: 0,
		shortcode: "DFz2ouKSTOm",
		comment: 31,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3538828156633485532.png",
		like: 1818,
		view: 0,
		shortcode: "DEccXV9ShDc",
		comment: 12,
	},
	{
		mediaThumbnail:
			"https://files.influence.id/influenceid-scraper-bucket/instagram/diansoediro_/posts/3559148950097801398.png",
		like: 1711,
		view: 0,
		shortcode: "DFkoxzpSLC2",
		comment: 23,
	},
];

export const dummyHashtags = [
	"#pempekdoscabe",
	"#palembang",
	"#pempek",
	"#mattitude",
	"#lorealmakeupid",
	"#makeup",
	"#findyourmattitude",
	"#lipcream",
	"#bestlippies",
];

export const dummyCreatorNetworks = [
	{
		targetInstagramMetric: {
			id: 741,
			username: "bams_pattikawa",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/bams_pattikawa/bams_pattikawa_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1612,
			username: "mespelor",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/mespelor/mespelor_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1613,
			username: "mamang__sule",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/mamang__sule/mamang__sule_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 742,
			username: "nikki_fz",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/nikki_fz/nikki_fz_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 744,
			username: "shejulia__",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/shejulia__/shejulia___thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1596,
			username: "hanan_attaki",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/hanan_attaki/hanan_attaki_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1601,
			username: "aquaridud_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/aquaridud_/aquaridud__thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1608,
			username: "gbrlababy",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/gbrlababy/gbrlababy_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1609,
			username: "ulfkrn",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/ulfkrn/ulfkrn_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1610,
			username: "bulanbuls_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/bulanbuls_/bulanbuls__thumb.png",
			isBrandAccount: "FALSE",
		},
	},
];

export const dummyCreatorNetworksTiktok = [
	{
		targetTiktokMetric: {
			id: 741,
			username: "bams_pattikawa",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/bams_pattikawa/bams_pattikawa_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1612,
			username: "mespelor",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/mespelor/mespelor_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1613,
			username: "mamang__sule",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/mamang__sule/mamang__sule_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 742,
			username: "nikki_fz",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/nikki_fz/nikki_fz_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 744,
			username: "shejulia__",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/shejulia__/shejulia___thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1596,
			username: "hanan_attaki",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/hanan_attaki/hanan_attaki_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1601,
			username: "aquaridud_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/aquaridud_/aquaridud__thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1608,
			username: "gbrlababy",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/gbrlababy/gbrlababy_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1609,
			username: "ulfkrn",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/ulfkrn/ulfkrn_thumb.png",
			isBrandAccount: "FALSE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1610,
			username: "bulanbuls_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/bulanbuls_/bulanbuls__thumb.png",
			isBrandAccount: "FALSE",
		},
	},
];

export const dummyBrandMentions = [
	{
		targetInstagramMetric: {
			id: 1602,
			username: "lorealindonesia",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/lorealindonesia/lorealindonesia_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1597,
			username: "sasse.studio",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/sasse.studio/sasse.studio_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1599,
			username: "pempekdoscabe_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/pempekdoscabe_/pempekdoscabe__thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1600,
			username: "dankeindonesia_official",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/dankeindonesia_official/dankeindonesia_official_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1603,
			username: "baesicaddict",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/baesicaddict/baesicaddict_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1604,
			username: "soule.bag",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/soule.bag/soule.bag_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1606,
			username: "theexceltonhotel",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/theexceltonhotel/theexceltonhotel_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetInstagramMetric: {
			id: 1607,
			username: "monrineofficial",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/monrineofficial/monrineofficial_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
];

export const dummyBrandMentionsTikTok = [
	{
		targetTiktokMetric: {
			id: 1602,
			username: "lorealindonesia",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/lorealindonesia/lorealindonesia_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1597,
			username: "sasse.studio",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/sasse.studio/sasse.studio_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1599,
			username: "pempekdoscabe_",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/pempekdoscabe_/pempekdoscabe__thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1600,
			username: "dankeindonesia_official",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/dankeindonesia_official/dankeindonesia_official_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1603,
			username: "baesicaddict",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/baesicaddict/baesicaddict_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1604,
			username: "soule.bag",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/soule.bag/soule.bag_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1606,
			username: "theexceltonhotel",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/theexceltonhotel/theexceltonhotel_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
	{
		targetTiktokMetric: {
			id: 1607,
			username: "monrineofficial",
			avatarUrl:
				"https://files.influence.id/influenceid-scraper-bucket/instagram/monrineofficial/monrineofficial_thumb.png",
			isBrandAccount: "TRUE",
		},
	},
];
