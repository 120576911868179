import Coins from "@/assets/icons/Coins.svg";
import { cn } from "@/common/helper";
import tokenServices from "@/services/token-services";
import type { User } from "@/types/master";
import { LoaderCircleIcon } from "lucide-react";
import { useEffect, useRef } from "react";

interface SectionOverlayProps {
	overlay: boolean;
	isAnalyzing: boolean;
	setIsAnalyzing: (value: boolean) => void;
	analyzeInfluencer: () => void;
	children: React.ReactNode;
	displayType?: "public" | "analytics" | "er_calc";
	containerClassName?: string;
}
const SectionOverlay: React.FC<SectionOverlayProps> = ({
	overlay,
	isAnalyzing,
	analyzeInfluencer,
	children,
	displayType,
	containerClassName,
}) => {
	const userRef = useRef<User>({
		email: null,
		name: null,
		isEmailVerified: false,
	});

	useEffect(() => {
		const userData = tokenServices.getUser() || {
			email: null,
			name: null,
			isEmailVerified: false,
		};
		if (userData.email) {
			userRef.current = userData;
		}
	}, []);
	return (
		<div className={cn("relative mt-4", containerClassName)}>
			{/* Overlay */}
			{!overlay && displayType != "analytics" && (
				<div
					className="absolute flex w-[105%] h-[105%] z-[900] rounded-lg border-2 border-red-500 float-right"
					style={{ left: "-2.5%", top: "-2.5%" }}
				>
					<span className="w-full text-right pr-4 pt-2 text-red-500">
						NOT THE ACTUAL RESULT
					</span>
				</div>
			)}
			<div
				className={cn(
					`absolute flex items-center justify-center w-[105%] h-[105%] bg-grey-800/40 backdrop-blur rounded-lg`,
					overlay ? `flex z-[900]` : "hidden"
				)}
				style={{ left: "-2.5%", top: "-2.5%" }}
			>
				<div className="flex flex-col justify-center items-center gap-5">
					{isAnalyzing ? (
						<>
							<LoaderCircleIcon className="animate-spin text-white" size={50} />
							<div className="flex flex-col items-center gap-1 text-white text-lg">
								<span className="font-medium">Analyzing . . .</span>
								<span>
									This may take a while. We will send a notification to your
									email once it is finished.
								</span>
							</div>
							<button className="flex items-center w-fit bg-gradient-to-r from-orange-600 to-orange-400 hover:opacity-90 border border-white text-white font-medium py-4 px-10 gap-2 rounded-2xl">
								Analyzing List Progress
							</button>
						</>
					) : (
						<div
							className="mr-2 h-8 sm:w-auto flex items-center cursor-pointer shadow-none sm:shadow rounded-full"
							onClick={() => {
								analyzeInfluencer();
							}}
						>
							<button className="flex justify-center items-center gap-1 sm:px-1 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 group hover:text-orange-700 rounded-full bg-orange-500 text-white font-normal shadow-md z-[999]">
								<span className="block whitespace-nowrap pb-0.5 pr-1 pl-4 my-1 text-lg">
									Analyze
								</span>

								{userRef.current && userRef.current.email ? (
									<div className="flex flex-row items-center justify-center rounded-full bg-orange-300 sm:bg-gray-50 px-2">
										<img
											src={Coins}
											alt="coins"
											className="min-w-5 max-w-5 min-h-5 max-h-5 mr-1"
										/>
										<span className="py-0.5 group-hover:text-orange-500 w-2 text-orange-700 sm:text-orange-500 text-lg">
											{5}
										</span>
									</div>
								) : (
									<span className="pr-2"></span>
								)}
							</button>
						</div>
					)}
				</div>
			</div>

			{/* Section Content */}
			{children}
		</div>
	);
};

export default SectionOverlay;
