/* eslint-disable unicorn/filename-case */
import EditPayment from "@/pages/settings/payment-settings/edit-payment";
import tokenServices from "@/services/token-services";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/settings/payment_/edit-payment")({
	component: EditPayment,
	beforeLoad: () => {
		if (!tokenServices.getLocalVerifToken()) {
			throw redirect({ to: "/login" });
		}
	},
});
