import { FlaskConical } from "lucide-react";
import Tooltip from "./tooltip";

const BetaTag: React.FC = () => {
	return (
		<Tooltip tooltipContent="This feature is in beta testing">
			<div className="flex flex-row items-center gap-1 rounded-full bg-orange-100 p-1 px-2 cursor-pointer">
				<FlaskConical className="text-orange-700" size={20}></FlaskConical>
				<span className="text-orange-700">Beta</span>
			</div>
		</Tooltip>
	);
};

export default BetaTag;
