import type { Balance } from "@/services/account-services";
import { create } from "zustand";

interface IBalanceStore {
	balance: Balance | null;
	setBalance: (data: Balance) => void;
	resetBalance: () => void;
}

export const useBalanceStore = create<IBalanceStore>()((set) => ({
	balance: null,
	setBalance: (newData: Balance): void => {
		set({ balance: newData });
	},
	resetBalance: (): void => {
		set({ balance: null });
	},
}));
