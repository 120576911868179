import Button from "@/components/ui/button";
import { EyeIcon, EyeOffIcon, LockKeyholeIcon } from "lucide-react";

const FloatingButton: React.FC<{
	overlay: boolean;
	setOverlay: (overlay: boolean) => void;
}> = ({ overlay, setOverlay }) => {
	return (
		<div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 px-2 flex items-center justify-center w-full z-[1000]">
			<div className="flex flex-col sm:flex-row items-center justify-between py-4 sm:px-5 gap-2 sm:gap-6 w-screen sm:w-full max-w-screen-xl h-fit bg-grey-500 rounded-xl shadow-lg">
				<div className="flex items-center gap-4">
					<div className="p-2 border-2 bg-white/60 text-grey-400 rounded-2xl">
						<LockKeyholeIcon color="#fff" />
					</div>
					<div className="flex flex-col text-white">
						<span className="font-semibold">Restriction on Viewing</span>
					</div>
				</div>

				<div className="flex items-center px-4 mt-2 gap-4 w-full sm:w-fit">
					<Button
						variant="outline"
						size="sm"
						className="bg-white hover:bg-white/80 text-nowrap flex-1 px-4"
						startIcon={
							overlay ? <EyeIcon size={16} /> : <EyeOffIcon size={16} />
						}
						onClick={() => {
							setOverlay(!overlay);
						}}
					>
						{overlay ? "See Example" : "Hide Example"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FloatingButton;
