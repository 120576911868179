import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import Modal from "../ui/modal";
import { InputForm } from "../ui/input";
import { CheckIcon, XIcon } from "lucide-react";
import { useChangePassword } from "@/hooks/account-hooks";
import toast from "react-hot-toast";
import type { AxiosError } from "axios";
import type { ChangePasswordFormData } from "./validation-schema";
import { changePasswordSchema } from "./validation-schema";

interface IChangePasswordModal {
	isOpen: boolean;
	onClose: () => void;
}

interface ErrorResponse {
	message: string;
}

const isAxiosError = (error: unknown): error is AxiosError<ErrorResponse> => {
	return (error as AxiosError<ErrorResponse>).isAxiosError !== undefined;
};

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({
	isOpen,
	onClose,
}) => {
	const methods = useForm<ChangePasswordFormData>({
		resolver: yupResolver(changePasswordSchema),
		mode: "onChange",
	});

	const newPasswordValue = methods.watch("newPassword");

	const changePassword = useChangePassword();

	const [passwordRequirements, setPasswordRequirements] = useState({
		hasMinLength: "default",
		hasCapitalLetter: "default",
		hasNumber: "default",
	});

	useEffect(() => {
		if (newPasswordValue) {
			setPasswordRequirements({
				hasMinLength:
					newPasswordValue.length >= 8 && newPasswordValue.length <= 20
						? "valid"
						: "invalid",
				hasCapitalLetter: /[A-Z]/.test(newPasswordValue) ? "valid" : "invalid",
				hasNumber: /\d/.test(newPasswordValue) ? "valid" : "invalid",
			});
		} else {
			setPasswordRequirements({
				hasMinLength: "default",
				hasCapitalLetter: "default",
				hasNumber: "default",
			});
		}
	}, [newPasswordValue]);

	const onSubmit: SubmitHandler<ChangePasswordFormData> = (data): void => {
		const payload = {
			oldPassword: data.currentPassword,
			newPassword: data.confirmPassword,
		};

		changePassword.mutate(payload, {
			onSuccess: () => {
				toast.success("Password has been saved!");
				onClose();
			},
			onError: (error: unknown) => {
				if (isAxiosError(error)) {
					const errorMessage: string =
						typeof error.response?.data?.message === "string"
							? error.response.data.message
							: "An error occurred";
					toast.error(errorMessage);
				} else {
					console.error("Unexpected error:", error);
				}
			},
		});
	};

	// Wrapper function to handle the promise returned by handleSubmit
	const handleSubmit = (): void => {
		methods
			.handleSubmit(onSubmit)()
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={() => {
				onClose();
				methods.reset();
			}}
			title="Set new password"
			description="Must be at least 8 characters"
			onSubmit={handleSubmit} // Use the wrapper function here
		>
			<div className="flex flex-col w-full gap-4 px-4">
				<FormProvider {...methods}>
					<InputForm
						type="password"
						name="currentPassword"
						placeholder="Current Password"
						label="Current Password"
					/>
					<div className="flex flex-col gap-2">
						<InputForm
							type="password"
							name="newPassword"
							placeholder="New Password"
							label="New Password"
						/>
						<div className="flex flex-col gap-1 text-grey-300 text-sm">
							<span>Password must include:</span>
							<ul className="flex flex-col gap-1">
								<li
									className={`flex items-center gap-1 ${
										passwordRequirements.hasMinLength === "invalid"
											? "text-red-500"
											: passwordRequirements.hasMinLength === "valid"
												? "text-green-400"
												: ""
									}`}
								>
									{passwordRequirements.hasMinLength === "invalid" ? (
										<XIcon size={16} />
									) : (
										<CheckIcon size={16} />
									)}
									<span>8 - 20</span>
									<span className="font-semibold">characters</span>
								</li>
								<li
									className={`flex items-center gap-1 ${
										passwordRequirements.hasCapitalLetter === "invalid"
											? "text-red-500"
											: passwordRequirements.hasCapitalLetter === "valid"
												? "text-green-400"
												: ""
									}`}
								>
									{passwordRequirements.hasCapitalLetter === "invalid" ? (
										<XIcon size={16} />
									) : (
										<CheckIcon size={16} />
									)}
									<span>At least</span>
									<span className="font-semibold">one capital letter</span>
								</li>
								<li
									className={`flex items-center gap-1 ${
										passwordRequirements.hasNumber === "invalid"
											? "text-red-500"
											: passwordRequirements.hasNumber === "valid"
												? "text-green-400"
												: ""
									}`}
								>
									{passwordRequirements.hasNumber === "invalid" ? (
										<XIcon size={16} />
									) : (
										<CheckIcon size={16} />
									)}
									<span>At least</span>
									<span className="font-semibold">one number</span>
								</li>
							</ul>
						</div>
					</div>

					<InputForm
						type="password"
						name="confirmPassword"
						placeholder="Confirm Password"
						label="Confirm The New Password"
					/>
				</FormProvider>
			</div>
		</Modal>
	);
};

export default ChangePasswordModal;
