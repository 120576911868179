import Coins from "@/assets/icons/Coins.svg";
import {
	formatDate,
	formatNumber,
	formatToIDR,
	handleDownload,
	hasAnalyticCredit,
	remainingCoins,
} from "@/common/helper";
import TableWithPagination from "@/components/charts/table";
import { Layout } from "@/components/layout";
import PaymentComponent from "@/components/subscription/midtrans";
import Button from "@/components/ui/button";
import { useGeneratePayment } from "@/hooks/payment-hooks";
import type { Invoice } from "@/services/payment-services";
import { getInvoice } from "@/services/payment-services";
import { useBalanceStore } from "@/store/credit-stores";
import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { DownloadIcon, LoaderCircleIcon } from "lucide-react";
import { useEffect } from "react";
import toast from "react-hot-toast";

const columnHelper = createColumnHelper<Invoice>();

const columns: Array<ColumnDef<Invoice, string>> = [
	columnHelper.accessor("createdAt", {
		cell: (info) => formatDate(info.getValue()),
		header: () => <span className="font-normal">Date</span>,
	}),

	columnHelper.accessor("orderDetails", {
		cell: (info) => {
			const orderDetails = info.getValue();
			const subscriptionPlanName = orderDetails?.[0]?.subscriptionPlan?.name;
			const quantity = orderDetails?.[0]?.quantity;

			let description = "";
			description +=
				subscriptionPlanName === "BASIC"
					? "Monthly Subscription"
					: `Purchase ${formatNumber(quantity || 0)} Coins`;

			return (
				<div className="whitespace-nowrap lg:whitespace-pre-line h-8 flex items-center font-normal">
					{description}
				</div>
			);
		},
		header: () => <span>Description</span>,
	}),
	columnHelper.accessor("amountDue", {
		cell: (info) => formatToIDR(info.getValue()),
		header: () => <span className="items-end self-en font-normal">Amount</span>,
	}),

	columnHelper.accessor("id", {
		cell: (info) => (
			<div
				onClick={(): void => {
					handleDownload(info.getValue())
						.then(() => {
							toast.success("Invoice Downloaded");
						})
						.catch((error) => {
							toast.error("Failed to download invoice");
							console.error(error);
						});
				}}
				className="flex items-center gap-2 text-orange-700 font-normal cursor-pointer"
			>
				<DownloadIcon size={16} />
				<span>Download</span>
			</div>
		),
		header: () => <span>Invoice</span>,
	}),
] as Array<ColumnDef<Invoice, string>>;

const MyPlan: React.FC = () => {
	const generatePayment = useGeneratePayment();
	const balance = useBalanceStore((state) => state.balance);

	const { data: invoiceDatas, isPending: invoiceDatasIsPending } = useQuery({
		queryKey: ["get-invoice-datas"],
		queryFn: async () => {
			const resp = await getInvoice();
			return resp;
		},
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		const scriptTag = document.createElement("script");
		scriptTag.src = import.meta.env["VITE_SB_MIDTRANS_URL"] as string;
		scriptTag.dataset["clientKey"] = import.meta.env[
			"VITE_SB_MIDTRANS_KEY"
		] as string;

		document.body.append(scriptTag);

		return () => {
			scriptTag.remove();
		};
	}, []);

	return (
		<Layout>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col gap-1">
					<span className="text-3xl text-grey-800 font-semibold">My Plan</span>
					<span className=" text-grey-500">
						See information regarding your plan.
					</span>
				</div>

				<hr />
			</div>

			<div className="flex flex-col xl:flex-row gap-2 sm:gap-6 my-2">
				<div className="flex flex-col w-full xl:w-1/3 gap-2 p-4 sm:p-6 bg-white border border-grey-100 rounded-xl shadow-md">
					<h2 className="text-xl text-grey-800 font-medium">My Balance</h2>
					<div className="flex flex-row items-center justify-between">
						<div className="flex flex-row items-center">
							<img src={Coins} alt="icons" className="h-6 w-6 mr-2" />
							<span className="text-3xl text-orange-500 font-semibold mb-1">
								{balance && hasAnalyticCredit(balance)
									? formatNumber(remainingCoins(balance))
									: 0}
							</span>
						</div>
						<div>
							<PaymentComponent>
								{(isPending: boolean): JSX.Element => (
									<Button
										size="sm"
										variant="primary"
										disabled={isPending}
										startIcon={
											generatePayment.isPending && (
												<LoaderCircleIcon className="animate-spin" />
											)
										}
										className="text-white font-semibold bg-orange-500 py-2 px-4 rounded-full sm:w-fit lg:w-32"
									>
										+ Add More
									</Button>
								)}
							</PaymentComponent>
						</div>
					</div>
				</div>
			</div>
			{/* Transaction History */}
			<div className="flex flex-col gap-4 my-2">
				<div className="flex flex-col gap-1">
					<span className="text-3xl text-grey-800 font-semibold">
						Transaction History
					</span>
					<span className=" text-grey-500">
						See information regarding your payment history.
					</span>
				</div>

				<hr />
			</div>
			{invoiceDatasIsPending && <LoaderCircleIcon className="animate-spin" />}
			{invoiceDatas && !invoiceDatasIsPending && (
				<TableWithPagination data={invoiceDatas} columns={columns} />
			)}
		</Layout>
	);
};

export default MyPlan;
