import InputRadio from "@/components/forms/input-radio";
import SelectForm from "@/components/forms/select";
import { Layout } from "@/components/layout";
import Button from "@/components/ui/button";
import { InputForm } from "@/components/ui/input";
import { useCompany } from "@/hooks/account-hooks";
import { getAccount } from "@/services/account-services";
import { getCities, getProvince } from "@/services/master-services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { ChevronLeftIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

type TProvince = {
	name: string;
	id: number;
};

type TCity = {
	name: string;
	regionType: number;
	id: number;
	provinceId: number;
};

export const editPaymentSchema = yup.object({
	name: yup.string().required(),
	province: yup
		.object({ label: yup.string().required(), value: yup.number().required() })
		.required(),
	city: yup
		.object({ label: yup.string().required(), value: yup.number().required() })
		.required(),
	industry: yup.number().required(),
	address: yup.string().required(),
	taxId: yup.string().required(),
	website: yup.string().nullable().notRequired(),
});

const businessTypeOpt = [
	{ label: "Brand", value: 1 },
	{ label: "Agency", value: 2 },
	{ label: "Other", value: 3 },
];

const EditPayment: React.FC = () => {
	const navigate = useNavigate();

	const { data: account } = useQuery({
		queryKey: ["get-account"],
		queryFn: async () => {
			const response = await getAccount();
			return response;
		},
	});

	const updateCompany = useCompany();

	const methods = useForm<yup.InferType<typeof editPaymentSchema>>({
		resolver: yupResolver(editPaymentSchema),
		defaultValues: {
			name: account?.company?.name || "",
			industry: account?.company?.industry || 0,
			website: account?.company?.website || "",
			taxId: account?.company?.taxId || "",
			address: account?.company?.address || "",
		},
	});

	const [provinceOpt, setProvinceOpt] = useState<
		Array<{ label: string; value: number }>
	>([]);
	const [cityOpt, setCityOpt] = useState<
		Array<{ label: string; value: number }>
	>([]);

	const { data: provinces } = useQuery({
		queryKey: ["get-province"],
		queryFn: async () => {
			const response = await getProvince();
			return response;
		},
	});

	const { data: cities } = useQuery({
		queryKey: ["get-cities"],
		queryFn: async () => {
			const response = await getCities();
			return response;
		},
	});

	const provinceField = useWatch({
		control: methods.control,
		name: "province",
	});

	useEffect(() => {
		if (account) {
			methods.reset({
				name: account.company.name,
				industry: account.company.industry,
				website: account.company.website,
				address: account.company.address,
				taxId: account.company.taxId,
			});
		}
	}, [account, methods]);

	useEffect(() => {
		if (provinces) {
			const filteredData = provinces.map((province: TProvince) => ({
				label: province.name,
				value: province.id,
			}));
			setProvinceOpt(filteredData);
		}
	}, [provinces]);

	useEffect(() => {
		if (provinceField) {
			const filteredCity = cities?.filter(
				(city: TCity) => city.provinceId === provinceField.value
			);
			const cityOptions = filteredCity?.map((city: TCity) => ({
				label: city.name,
				value: city.id,
			}));
			setCityOpt(cityOptions || []);
		}
	}, [provinceField, cities]);

	const handleAddressOpt = useCallback(() => {
		const selectedCity = cities?.find(
			(city: TCity) => city.id === account?.company?.cityId
		);
		const selectedProvince = provinces?.find(
			(province: TProvince) => province.id === selectedCity?.provinceId
		);

		const selectedProvinceOpt = {
			label: selectedProvince?.name || "",
			value: selectedProvince?.id || 0,
		};
		const selectedCityOpt = {
			label: selectedCity?.name || "",
			value: selectedCity?.id || 0,
		};

		if (selectedProvinceOpt.label) {
			methods.setValue("province", selectedProvinceOpt);
			methods.setValue("city", selectedCityOpt);
		}
	}, [cities, provinces, account, methods]);

	useEffect(() => {
		handleAddressOpt();
	}, [handleAddressOpt]);

	const onSubmit = (data: yup.InferType<typeof editPaymentSchema>): void => {
		const payload = {
			cityId: data["city"].value,
			name: data["name"],
			industry: data["industry"],
			address: data["address"],
			taxId: data["taxId"],
			...(data["website"] ? { website: data["website"] } : null),
		};

		updateCompany.mutate(payload, {
			onSuccess: () => {
				toast.success("Payment info changed!");
				navigate({ to: "/settings/payment" }).catch((error) => {
					console.error(error);
				});
			},
			onError: (error: unknown) => {
				if (error instanceof AxiosError) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
					toast.error(error.response?.data.message);
				} else {
					console.error("Unexpected error:", error);
				}
			},
		});
	};

	return (
		<Layout>
			<div className="flex flex-col gap-4">
				<Link
					to="/settings/payment"
					className="flex items-center gap-1 text-lg font-semibold hover:text-orange-500"
				>
					<ChevronLeftIcon />
					<span>Back</span>
				</Link>
				<hr />
			</div>
			<span className="font-semibold text-lg">Edit Payment Information</span>
			<div className="flex flex-col w-full max-w-screen-md gap-4">
				<FormProvider {...methods}>
					<InputForm
						name="name"
						label="Company Name"
						inputClassname="bg-white"
						placeholder="e.g. PT ABC Company"
					/>
					<InputRadio
						name="industry"
						values={businessTypeOpt}
						label="Business Type"
					/>
					<div className="flex flex-col gap-2">
						<InputForm
							name="website"
							label="Website (Optional)"
							inputClassname="bg-white"
							placeholder="e.g. www.website.com"
						/>
					</div>
					<SelectForm name="province" label="Province" options={provinceOpt} />
					<SelectForm name="city" label="City" options={cityOpt} />
					<InputForm
						name="address"
						label="Address"
						inputClassname="bg-white"
						placeholder="e.g. Jl. Jendral Sudirman No. 12"
					/>
					<InputForm
						name="taxId"
						label="Tax ID (NPWP)"
						inputClassname="bg-white"
						placeholder="e.g. 123.456.789.123"
					/>
					<div className="flex w-full justify-end gap-2">
						<Button
							variant="outline"
							size="sm"
							className="w-fit"
							onClick={() => {
								navigate({ to: "/settings/payment" }).catch((error) => {
									console.error(error);
								});
							}}
						>
							Cancel
						</Button>
						<Button
							variant="primary"
							size="sm"
							className="whitespace-nowrap w-fit"
							onClick={() => {
								methods
									.handleSubmit(onSubmit)()
									.catch((error) => {
										console.error(error);
									});
							}}
						>
							Save Changes
						</Button>
					</div>
				</FormProvider>
			</div>
		</Layout>
	);
};

export default EditPayment;
